.table-asuntos .ant-table-cell{
    text-align: start !important;
    padding-left: 15px !important;
}


.modal-asunto .modal-title{
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #04FAA1;
}


.modal-asunto .input-box{
    background: #222325 !important;
    padding: 1em;
    box-shadow: inset -4px -2px 8px #575757, inset 4px 2px 8px rgba(13, 13, 13, 0.48);
    border-radius: 20px!important;

}


.modal-asunto .submit-button{
    margin: 1em auto;
    display: block;
    background: linear-gradient(88.33deg, #00E676 -7.64%, #00E676 145.94%);
    box-shadow: -6px -6px 16px rgba(255, 255, 255, 0.1), 6px 9px 40px rgba(40, 173, 69, 0.54);
    border-radius: 8px;
}

.modal-asunto .form-item-content .ant-form-item-label{
    text-align: center;
    margin-top: 1em;
    margin-bottom: 1em;
}