canvas{
    width: 100% !important;
    overflow: auto !important;
    position: relative !important;
    float: left !important;
    /* padding:10px !important; */
    z-index: 1000;
}



.divSolAcceso .ntf-SolAcceso{
   border-left: solid 5px #FAE104 !important;
   float: left;
}



.divSolAcceso .ntf-SolAcceso .div-solAcceso {
    height: auto !important;
    display: block !important;
    float: left ;
    margin-top: 5px !important;
}

.div-secNtf {
    float: left !important;
    max-width: 100% !important;
    max-height: 670px !important;
    padding: 0px !important;
    padding-bottom: 10px !important;
     overflow: auto !important; 
}

.div-secNtf .div-notificacion{
    width: 100% !important;
    padding-bottom: 5px !important;
    padding-left: 15px !important;
    margin-top: 5px !important;
}

.div-secNtf .div-notificacion label{
    
    padding: 2px 0px !important;
    max-width: 96% !important;
    text-align: left !important;
    height:  auto !important;
    position: initial !important;
    display: block !important;
    overflow: auto;
}

.spn-muesca{
     
    width: 6px !important;
    min-height: 50px !important;
    position: initial;
    background: #8d8d8d;
    margin-left: -5px;
}

.ntf-nivel1{
    background: #00d870!important;
}
.ntf-nivel2{
    background: #f8cf3e !important;
}
.ntf-nivel3{
    background: #7b31f5 !important;
}

.ntf-nivel4{
    background: #f14181 !important;
}


.div-accesos .row-titulo{
    text-align: center;
    margin: 10px 0px;
}

.div-accesos .txt-titulo{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #C8D3F1;
}


.div-accesos .div-notificacion{
    text-align: center;
    background: #2C2F33 !important;
    box-shadow: -6px -6px 15px #3B4451, 6px 6px 15px #000000 !important;
    margin: 20px 15px !important;
    width: calc(100% - 30px) !important;

}


.div-accesos .div-notificacion .col-infoAcceso{
  
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 50px;
    color: #FFFFFF;
}
.txt-infoAcceso{
    
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: #FFFFFF;

}

.row-notificacion{
    
    text-align: left;
    width: 100%;
    display: inline-block;
    padding: 0px 5px;
    
}

.span-ntf {
    display: block;
    width:  22px !important;
    height: 22px !important;
    border-radius: 50px !important;
    background: linear-gradient(126.63deg, #2A9F9E 11.98%, #2A9F9E 83.35%) ;
    margin-top: 20px;
    margin-left: 20%;
    border: solid 6px rgba(255, 255, 255, 0.05);
}

.btn-goto-notificacion{
    width:  45px !important;
    height: 45px !important;
    background: #2C2F33 !important;
    border-radius: 15px !important;
    box-shadow: -6px -6px 15px #3B4451, 6px 6px 15px #000000 !important;
    margin-top: 10px !important;
    border:none !important;
    float: right !important;
    padding: 0px !important;
    padding-left: 5px !important;
    padding-top: 5px !important;
    margin-right:  12px !important;

}


.col-ntf-info{padding-right: 20px;}

.nivel1{
    background: #3e57ff; 
    border: solid 6px  #476a94;
}

.nivel2{
    background:#f8cf3e ; 
    border: solid 6px #909338;
}

.nivel3{
    background: #ff3737 ;
    border: solid 6px  #93181a;
}



.col-ntf-info{
    padding-left: 10px !important;
    padding-right: 20px !important;
    color: white !important;}


.col-ntf-info strong{
    display: block;
    margin-bottom: 0px !important;
}

.col-ntf-info .ant-typography,
.col-ntf-info p
{
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    line-height: 12px;
    color: #FFFFFF;
    margin-bottom: 7px !important;
}