.header{
  display: flex;
  width: 100vw !important;
  max-width: 100% !important;
  height: 96px;
  background: linear-gradient(94.6deg, #32383E -60.81%, #17191C 185.78%)!important;
  box-shadow: -5px -6px 16px rgba(195, 200, 205, 0.04), 6px 6px 28px rgba(0, 0, 0, 0.3);
 

filter: drop-shadow(-6.22302px -6.22302px 18.6691px #3B4451) drop-shadow(6.22302px 6.22302px 18.6691px #000000) !important;
background: #2C2F33 !important;
box-shadow: -5px -6px 8px rgba(195, 200, 205, 0.05), 
              6px 6px 5px rgba(0, 0, 0, 0.5) !important;


}

.header-content{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  float: right;
  min-height: 50px;
  

}

.header-logo{
  height: 110px;
}

.header-logo .logo{
  margin: 15px;
  height: auto;
  max-width: 350px;
}


.header-options{
   display: flex; 
  /* width: 250px; */
  margin-right: 20px;
  align-items: center;
  vertical-align: middle;
  height: 100%;
  justify-content: flex-end;
}

.header-options div{
  width: auto !important;
  display: inline-block;
}
.IconoBell {
  color:#2fb29d; 
  font-size:32px;  
  /* position: relative; */
  top: 10px;
}
.IconoBell svg{
  margin-top: 10px;
}

.Menu{
  float: left;
}

.Header .ant-badge-count, .ant-badge-dot, .ant-badge .ant-scroll-number-custom-component{
  top: 8px;
  right: 14px;
}


.notifications-list{
  min-width: 250px;
  max-height: 345px;
  overflow: auto;
  border-bottom: 1px solid #eaeaea;
  background:  linear-gradient(94.6deg, #32383E -60.81%, #2c2f33 185.78%)!important;
  background:  #171819  !important;
}


.notifications-list .ant-menu-item a{
  color: #33F898 ;
  margin-left: 5px;
}


.notification-element{
  border-bottom: 1px solid #d6d6d6;
  height: auto!important;
}

.reciente{
  position: absolute;
  top: 13px;
  right: 0px;
}
