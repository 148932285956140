.modal-detAccesos .lblTitleItemForm{
    margin-left: 20px !important;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #899AA4 !important;
    margin-bottom: 10px !important;
}

.modal-detAccesos .btn-form{
    padding: 15px !important;
    text-align: center !important;
    float: left !important;
    
}


.modal-detAccesos .btn-verde{
    width: auto !important;
    height: auto !important;
    border: 1px solid transparent !important;
    background: linear-gradient( 88.33deg , #00E676 -7.64%, #00E676 145.94%) !important;
    box-shadow: -6px -6px 5px rgb(255 255 255 / 5%), 6px 8px 15px rgb(40 173 69 / 35%) !important;
    border-radius: 8px !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    color:#fff !important;
    text-align: center !important;
    float: left !important;
    margin: 25px auto !important; 
    min-width: 150px !important;
    margin-left: calc(50% - 80px ) !important;
}

.register-form .select-form{
    background: linear-gradient(210.55deg, #121416 -3.02%,  #353A40  93.08%);
    box-shadow: inset -16px -6px 80px rgba(248, 249, 249, 0.03);
    border-radius: 24px;
    height: 45px !important;
    padding-top: 8px !important;
}



/*.register-form  .ant-select-arrow ,*/
.register-form  .ant-input-suffix 
{
    display: none !important;
    visibility: hidden !important;
}

 

.register-form .ant-select-selection-search {
    border: none !important;
}

.register-form .svg-IconSelectArrowModal{
    position: inherit !important;
    display: block !important;
    margin-top:-73px !important;
    margin-right: 29px !important;
    z-index: 10 !important;
    transition-delay: 0s !important;
    float: right !important;
}


.register-form  .ant-form-item-has-error{
    margin-bottom: 0px !important;
}


.register-form  .ant-form-item-has-error .ant-select-selector > span.ant-select-arrow {
    display: none !important;
    visibility: hidden !important;
}

.register-form .ant-col{
    overflow: hidden !important;
}

.register-form .ant-input-prefix .anticon{
    color:#899AA4 !important; 
    margin-right: 5px !important;
}