.item-access-row-column{
    text-align: center !important;
}

.item-access-row-column .svg-IconEye {
margin-left: 5px ;
}


.item-db-row{
    height: auto !important;
}

.item-db-row .muesca{
    height: 90% !important;
}