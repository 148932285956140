:root {
	--aide-gray: #282c31;
	--aide-green:  #04FAA1;
}

.center{
	display: flex;
	justify-content: center;
	align-items: center;
}

.top_up{
	top: 50px !important;
}

/*CSS GENERAL PARA LOS MODALES*/

.modal-aide .ant-modal-content{
	background-color: var(--aide-gray);
	border-radius: 12px;
}

.modal-aide .ant-modal-body{
	padding-bottom: 3rem;
}

.modal-aide .modal-header{
	position: relative;
	min-height: 38px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal-aide .btn-close-modal,
.modal-aide .btn-close-modal:hover,
.modal-aide .btn-close-modal:focus{
	position: absolute;
	background: transparent;
	padding: 0;
	top: 1px;
	left: 0;
	height: 30px;
	border: none;
}

.logo-aide-modal{
	width: 100px;
}

.modal-aide .modal-title{
	color: var(--aide-green);
	font-family: Rubik;
	font-style: normal;
	font-weight: 500;
	font-size: 35px;
	letter-spacing: 0.1px;
	margin: 1rem;
	text-align: center;
}

.modal-aide .modal-sub-title{
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	text-align: center;
	color: #899AA4;
	margin-bottom: 1rem;
}

.modal-aide .btn-green,
.modal-aide .btn-green:hover,
.modal-aide .btn-green:active,
 .modal-aide .btn-green:focus{
	background: linear-gradient(88.33deg, #00E676 -7.64%, #00E676 145.94%);
	box-shadow: -6px -6px 16px rgba(255, 255, 255, 0.1), 6px 9px 40px rgba(40, 173, 69, 0.54);
	border-radius: 8px;
	min-width: 150px;
	font-weight: 500;
	letter-spacing: 0.2px;
	border: none;
	margin-top: 1rem;
}

/*CSS PARA LOS INPUTS DENTRO DE  LOS MODALES*/

.modal-aide .input-prefix .ant-input,
.modal-aide .input-prefix .ant-input:hover{
	background: transparent !important;
	padding-left: 6px !important;
	font-size: 15px;
}

.modal-aide .ant-form-item-explain{
	margin: 0;
}

/*inputs tipo texto*/
.modal-aide .input-modal{
	background: #222325 !important;
	box-shadow: inset -4px -2px 8px #575757, inset 4px 2px 8px rgba(13, 13, 13, 0.48);
	border-radius: 20px;
	height: 32px;
}


.ant-picker {
 border-color:transparent !important;
 border: 1px solid transparent !important;
}

/*selects*/
.modal-aide .select-modal .ant-select-selector{
	background: linear-gradient(210.55deg, #353A40 -3.02%, #121416 93.08%) !important;
	box-shadow: inset -16px -6px 80px rgba(248, 249, 249, 0.03);
	border-radius: 24px !important;

	background: linear-gradient( 210.55deg , #121416 -3.02%, #353A40 93.08%) !important;
}

.modal-aide .select-modal .ant-select-selection-search{
	border-right: none;
}


.modal-aide .subtitle-modal{
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
	color: #899AA4;
	margin-left: 15px;
}
.modal-aide .modal-text{
	font-weight: 400;
	font-size: 18px!important;
	color: #899AA4;
	word-wrap: break-word;
}



.modal-aide .select-modal.select-large .ant-select-selector{
	height: 45px !important;
}

.modal-aide .select-modal.select-large .ant-select-selection-placeholder,
.modal-aide .select-modal.select-large .ant-select-selection-item{
	padding-top: 8px;
}

.modal-aide .select-modal .ant-select-selector:hover,
.modal-aide .select-modal *{
	border: none !important;
	border-right-width: 0px !important;
}


.modal-aide .ant-select,
.modal-aide .ant-select-selector,
.modal-aide input {
	min-height: 42px;
	margin-top: 0px;
}


.buttons-container{
	row-gap: 0px !important;
	flex-flow: unset !important;
	display: unset !important;
}

.btn- {
	font-size: 1.05em !important;
	border: none !important;
	margin: 6px;
	padding: 0.7em 2em !important;
	height: auto !important;
}


.btn-accept{
	background: #2A9F9E !important;
	box-shadow: 6px 9px 40px rgba(42, 159, 158, 0.37) !important;
	border-radius: 8px !important;
	float: right;
}

.btn-denied{
	background: #C51F29 !important;
	box-shadow: 6px 9px 40px rgba(255, 0, 0, 0.25)  !important;
	border-radius: 8px  !important;
}

.border{
	border: solid 1px red !important;
}

.border2{
	border: solid 1px blue;
}