.floating-button {
    position: fixed;
    right: 2em;
    bottom: 2em;

    width: 70px !important;
    min-width: 70px !important;
    height: 70px !important;

    background: linear-gradient(126.63deg, #3FFCA0 11.98%, #00E676 83.35%);
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.35), 0px 4px 4px rgba(0, 0, 0, 0.25);

    border: none;

    
    transition: all 0.2s ease-in;
    animation: all 0.2s ease-in;

    border-radius: 100%;
}


.floating-button:hover{
    background: linear-gradient(126.63deg, #3FFCA0 11.98%, #00E676 83.35%);
    box-shadow: 0px 0px 9px 2px rgba(255, 253, 253, 0.55), 0px 4px 4px rgba(0, 0, 0, 0.25);

    font-size: 1em;

    width: 35px;
    border: none;
}


.floating-button svg{
    width: 35px;
    position: relative;
    top: 3px;
}