
.btnAddPlus{
    background: #12e764 ;
    width:  37px;
    height: 37px;
    border-radius: 5px;
    padding: 0px;
    font-weight: bolder;
    border: solid 3px #12e764 !important;
    color:#FFF;
    float: right;
    cursor: pointer;
}

.filter-form{
    width:calc(100% - 20px) !important;
    min-width: 80px;
    max-width: 600px;
    float:left;
}

.encabezado{
    margin-bottom: 50px;
    font-size: 25px!important;
}

.contenido{
    width: 100%;
    float: left;
}

.UserRegister .ant-table-content{
    background: rgba(255, 255, 255, 0) !important;
    background: #2a2b2d !important
}

.tablaUsuarios  th {
    color:#FFF !important;
    font-weight: 700;
    font-size: 13pt;
    text-align: center !important;
    background: transparent !important;
    -webkit-transition: background 0.3s ease;
    transition: background 0.3s ease;
    border-bottom: solid 2px #FFF;
    margin-bottom: 10px;
}
.tablaUsuarios .ant-table-tbody  {
   overflow: auto !important;
}
 

.tablaUsuarios .ant-table-tbody > tr > td {
    
    font-size: 11pt;
    text-align: center;
    border:none !important;
    background: #171819;
    border-bottom: solid 5px  #2a2b2d !important;
    padding:3px;
    min-height: 60px;
    height: 60px;
    max-height: auto;
}
.tablaUsuarios .ant-table tbody > tr.ant-table-row:hover > td{
    background: #589b9a !important;
    color:#FFF;
}
.tablaUsuarios tbody td:first-child{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.tablaUsuarios tbody td:last-child{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}


.tablaUsuarios .ant-table-tbody > tr:first-child td{
    border-top: solid 5px  #2a2b2d !important;
    
}
 
.spnAccionesUsu a
{
    margin: 5px;
    background: #000;
    border-radius: 5px;
    border: solid 1px #171819;
    padding:2px;
    width: 34px !important;
    height: 34px !important;
    float: initial;
    display: inline-flex;
    box-shadow: inset -2.5px -2.5px 1px #3e3f3f, inset 1px 1px 0.5px #171819;
    
}

.spnAccionesUsu a svg
{
    width: 25px !important;
    height: 25px !important;
    padding:3px;
    color: #FFF;
    border-radius: 3px;
}

.RecurIcon svg{
    background: #52c41a;
    border: solid 1px #b5ff60;
}

.EditIconUsu  svg {
    background: #0084ff;
    border: solid 1px #4ca9ff;
}
 

.div-headerUsuarios{
    padding-bottom: 10px !important;
}

.table-permisos{
    color: white;
    font-size: 15px;

}

.tag-permiso{
    background: transparent;
    border:1px solid #fff;
    color: #fff;
}
.table-permisos-row{
    background: #171819;
    border: 2px solid rgba(255, 255, 255, 0);
    box-sizing: border-box;
    border-radius: 6px 6px;
    margin: 10px;
    font-weight: 700;
    font-size: 20px;
    min-height: 50px;
    padding-left:1rem 
}

.tag-permisos{
    background: transparent;
    color:#899aa4;
    border: 1px solid #899aa4;
}

.text-gray{
    color: #899aa4;
}

.table-permisos-tbody .ant-form-item,
.table-permisos-tbody .ant-switch{
    margin: 0;
}


 
.svg-IconSearch{
    position: inherit !important;
    display: block !important;
    top:30px !important;
    margin-left: 10px !important;
    z-index: 100 !important;
    transition-delay: 0s !important;
    float: left !important;
}


.row-enc-usuarios  .input-search input.ant-input{
    padding-left: 30px !important;
    background: transparent !important;
}

.row-enc-usuarios .input-search{
    background: transparent !important;
    padding: 0px !important;
    
}

.row-enc-usuarios h1 {
        font-weight: 400 !important;
        font-size: 28px !important;
        color: #9EA7AC !important;
        margin: 0px !important;
}

.modal-form{
    padding-bottom: 0px !important;
}


.modal-detAccesos.modal-form  .btn-form{
    padding: 0px !important;
    margin: 0px !important;
}

.modal-form .row-info .ant-col {
    margin-bottom: 20px !important;
}


.selec-niveles{
    min-width: 175px !important;
}

.selec-niveles .ant-select-selection-placeholder{
    text-align: right !important;
    padding-right: 20px !important;
}
