@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap);
.Login{
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content: center;
          justify-content: center;
  min-height: 100vh;
  background:#161616;
}
.Login-Title{
  color: #899AA4;
  font-family: Roboto,sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
}
/* form container */
.login-form{
  position: relative;
  width: 350px;
  padding: 20px 20px ;
  text-align: center;
  background: linear-gradient(94.6deg, #32383E -60.81%, #17191C 185.78%);
  box-shadow: -5px -6px 16px rgba(195, 200, 205, 0.04), 6px 6px 28px rgba(0, 0, 0, 0.3);
  border-radius: 16px;
}


/* input label */
.login-form
.ant-form-item-label
{
    margin-bottom: 5px;
    vertical-align: center;
    text-align: center;
    float: none;
    float: initial;
    width: 100%;
    color: #899AA4;
    font-family: Roboto,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;

}




.login-form .ant-form-item-control input:hover,
.login-form .ant-form-item-control textarea:hover{
  border: none!important;
}
.login-form input{
  border: none !important;
  text-align: left;
}
 
 .login-form
 .ant-input-affix-wrapper .ant-input-prefix {
   font-size: 20px;
    color: #00E676;
}

 .login-form .ant-form-item-control input,
 .login-form .ant-form-item-control textarea
 {
    color:#899aa4;
    border-radius: 12px;
    background: #171819;
    border: none !important;
    font-size: 10pt !important;
    font-weight: 400;
   
}
form input, .ant-input-affix-wrapper, .ant-select-selection, .ant-select-selector,
input:-internal-autofill-selected{
  background: transparent !important;
}

.login-form .ant-input-affix-wrapper{
  background-color: transparent;
}


/* button submit */
.login-submit-btn{
  width: 174px!important;
  height: 43px!important;
  border:1px solid transparent!important;
  background: linear-gradient(88.33deg, #00E676 -7.64%, #00E676 145.94%)!important;
  box-shadow: -6px -6px 16px rgba(255, 255, 255, 0.1), 6px 9px 40px rgba(40, 173, 69, 0.54)!important;
  border-radius: 8px!important;
  font-weight: 700!important;
  font-size: 18px!important;

}
.login-submit-btn:hover,
.login-submit-btn:focus,
.login-submit-btn:active{
  border: 1px solid transparent!important;
  background: linear-gradient(88.33deg, #02b65f -7.64%, #00E676 145.94%)!important;
}

.link{
  color: #899AA4;
  padding: 10px 0px;
}
@media all and (min-width: 480px) {
    .Login {
      padding: 60px 0;
    }
  
    .Login form {
      margin: 0 auto;
      max-width: 400px;
    }
  }
/*.sidebar .ant-layout-sider-children{
    border: none;
    background: #090a0a;
    border-image: linear-gradient(to bottom, #28999d 5%, #090a0a 100%) 1;
    border-top: 1px;
    border-left: none;
    border-bottom: none;
}

.sidebar .ant-menu-inline {
    color: #fff;
}

.sidebar .ant-menu{
    background: linear-gradient(94.6deg, #32383E -60.81%, #17191C 185.78%)!important;
    box-shadow: -5px -6px 16px rgba(195, 200, 205, 0.04), 6px 6px 28px rgba(0, 0, 0, 0.3);
    border-radius: 16px;
    color: #fff;
    border-right-color: transparent;
    height: 100vh;

}
.ant-menu-submenu-popup,
.ant-menu-submenu-popup > .ant-menu{
    background: rgba(23,24,25,0.5) !important; 
    border-radius: 16px;
    color: #fff;
}
.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left{
    border-right-color: none;
}
.sidebar .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.sidebar .ant-menu-item:hover, 
.sidebar .ant-menu-item-active,
.sidebar .ant-menu-item:hover i:after,
.sidebar .ant-menu-item-active i:after,
.sidebar .ant-menu-item:hover i:before, 
.sidebar .ant-menu-item-active i:before,
.sidebar .ant-menu:not(.sidebar .ant-menu-inline) .sidebar .ant-menu-submenu-open,
.sidebar .ant-menu-submenu-active,
.sidebar .ant-menu-submenu-title:hover
{
    background: transparent;
    color: #00E676;
}


.sidebar .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after, 
.sidebar .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after, 
.sidebar .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after, 
.sidebar .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after, 
.sidebar .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before, 
.sidebar .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before, 
.sidebar .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before, 
.sidebar .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before{
    background: #fff;
}

.sidebar .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, 
.sidebar .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, 
.sidebar .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, 
.sidebar .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, 
.sidebar .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, 
.sidebar .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, 
.sidebar .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, 
.sidebar .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before{
    background:#00E676 ;
}

.sidebar .ant-menu-vertical .ant-menu-item::after, 
.sidebar .ant-menu-vertical-left .ant-menu-item::after, 
.sidebar .ant-menu-vertical-right .ant-menu-item::after, 
.sidebar .ant-menu-inline .ant-menu-item::after{
    border-right:3px solid #00E676;
}
.ant-menu.ant-menu-light .ant-menu-item:hover {
    background: transparent;
    color: #00E676;
}*/

/*.ant-menu-item-group {
    height: 32px;
    font-weight: 500;
}

.ant-menu-submenu ul {
    background: #1f2121 !important;
}

.ant-menu-inline-collapsed > .ant-menu-submenu {
    left: 0;
    padding: 0 0px !important;
    text-overflow: clip;
}

.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title > span > .anticon {
    margin-right: 30px;
}


.ant-menu-submenu.ant-menu-submenu-popup.ant-menu-dark.ant-menu-submenu-placement-leftTop { 
    max-width: 250px !important;
}*/

/*.sidebar .ant-menu-submenu ul{
    margin-left: 0;
    background: #171819!important;
    border-radius: 16px;
    box-shadow: none;
    height: auto;
}*/

/*----------------------------------------------------------------------*/

.sidebar{
    background: transparent !important;
    margin-bottom: 1rem;
    min-height: 100%;
    padding: 20px 5px!important;
    
}

.sidebar .nav-bar{
    height: 100%;
    max-height: 1000px;
    border-radius: 16px;
    background: linear-gradient(94.6deg, #32383E -60.81%, #17191C 185.78%);
    border-right: none;
    color: #fff;
    position: relative;
    
    
    
    min-height: 100%;
    background: #2C2F33 !important;
    box-shadow: -6.22302px -6.22302px 18.6691px #3b4451c2,
     6.22302px 6.22302px 18.6691px #000000 !important;
    border-radius: 24.8921px !important;

  

}

.sidebar .nav-bar .group-bottom{
    position: absolute;
    bottom: 0;
    width: 100%;
}

.sidebar .nav-bar svg{
   fill: #fff;
   width: 25px;
   height: 25px;
   min-width: 25px;
}

.sidebar .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background: transparent;
    color: #00E676;
}

.sidebar .ant-menu-item-active{
    color: #00E676 !important;
}

.sidebar .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected svg{
    fill: #00E676;
}

.sidebar .ant-menu-inline .ant-menu-item::after{
    border-right: 3px solid #00E676;
}

.sidebar .ant-menu-item:active{
    background: transparent;
}

.sidebar .nav-item{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.sidebar .nav-item .nav-text{
    margin-left: 12px;
    overflow: hidden;
}
canvas{
    width: 100% !important;
    overflow: auto !important;
    position: relative !important;
    float: left !important;
    /* padding:10px !important; */
    z-index: 1000;
}



.divSolAcceso .ntf-SolAcceso{
   border-left: solid 5px #FAE104 !important;
   float: left;
}



.divSolAcceso .ntf-SolAcceso .div-solAcceso {
    height: auto !important;
    display: block !important;
    float: left ;
    margin-top: 5px !important;
}

.div-secNtf {
    float: left !important;
    max-width: 100% !important;
    max-height: 670px !important;
    padding: 0px !important;
    padding-bottom: 10px !important;
     overflow: auto !important; 
}

.div-secNtf .div-notificacion{
    width: 100% !important;
    padding-bottom: 5px !important;
    padding-left: 15px !important;
    margin-top: 5px !important;
}

.div-secNtf .div-notificacion label{
    
    padding: 2px 0px !important;
    max-width: 96% !important;
    text-align: left !important;
    height:  auto !important;
    position: static !important;
    position: initial !important;
    display: block !important;
    overflow: auto;
}

.spn-muesca{
     
    width: 6px !important;
    min-height: 50px !important;
    position: static;
    position: initial;
    background: #8d8d8d;
    margin-left: -5px;
}

.ntf-nivel1{
    background: #00d870!important;
}
.ntf-nivel2{
    background: #f8cf3e !important;
}
.ntf-nivel3{
    background: #7b31f5 !important;
}

.ntf-nivel4{
    background: #f14181 !important;
}


.div-accesos .row-titulo{
    text-align: center;
    margin: 10px 0px;
}

.div-accesos .txt-titulo{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #C8D3F1;
}


.div-accesos .div-notificacion{
    text-align: center;
    background: #2C2F33 !important;
    box-shadow: -6px -6px 15px #3B4451, 6px 6px 15px #000000 !important;
    margin: 20px 15px !important;
    width: calc(100% - 30px) !important;

}


.div-accesos .div-notificacion .col-infoAcceso{
  
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 50px;
    color: #FFFFFF;
}
.txt-infoAcceso{
    
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    color: #FFFFFF;

}

.row-notificacion{
    
    text-align: left;
    width: 100%;
    display: inline-block;
    padding: 0px 5px;
    
}

.span-ntf {
    display: block;
    width:  22px !important;
    height: 22px !important;
    border-radius: 50px !important;
    background: linear-gradient(126.63deg, #2A9F9E 11.98%, #2A9F9E 83.35%) ;
    margin-top: 20px;
    margin-left: 20%;
    border: solid 6px rgba(255, 255, 255, 0.05);
}

.btn-goto-notificacion{
    width:  45px !important;
    height: 45px !important;
    background: #2C2F33 !important;
    border-radius: 15px !important;
    box-shadow: -6px -6px 15px #3B4451, 6px 6px 15px #000000 !important;
    margin-top: 10px !important;
    border:none !important;
    float: right !important;
    padding: 0px !important;
    padding-left: 5px !important;
    padding-top: 5px !important;
    margin-right:  12px !important;

}


.col-ntf-info{padding-right: 20px;}

.nivel1{
    background: #3e57ff; 
    border: solid 6px  #476a94;
}

.nivel2{
    background:#f8cf3e ; 
    border: solid 6px #909338;
}

.nivel3{
    background: #ff3737 ;
    border: solid 6px  #93181a;
}



.col-ntf-info{
    padding-left: 10px !important;
    padding-right: 20px !important;
    color: white !important;}


.col-ntf-info strong{
    display: block;
    margin-bottom: 0px !important;
}

.col-ntf-info .ant-typography,
.col-ntf-info p
{
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    line-height: 12px;
    color: #FFFFFF;
    margin-bottom: 7px !important;
}
.content{
    background-color: #090A0A;
}


.page-title{
    font-weight: 400 !important;
    font-size: 26px !important;
    color: #9EA7AC !important;
    padding-left: 2rem; 
    margin: 0px !important;
}


.page-header-title [title]{
    color: #9EA7AC!important;
    font-weight: normal!important;
    font-size: 28px;
}

.card-access{
    height: 130px !important;
}

.card-access.blue {
    background: rgba(0, 195, 255, 0.2);
    border: 2px solid #00C3FF;
    border-radius: 10px;
}

.card-access.purple{
    background: rgba(160, 0, 254, 0.2)!important;
    border: 2px solid #A000FE!important;
    border-radius: 10px!important;
}

.card-access.green {
    background: rgba(35, 234, 164, 0.2);
    border: 2px solid #23EAA4;
    border-radius: 10px;
}

.card-access.yellow{
    background: rgba(254, 197, 0, 0.2);
    border: 2px solid #FEC500;
    border-radius: 10px;
}

.table-access-container{
    background: linear-gradient(94.6deg, #32383E -60.81%, #17191C 185.78%);
    border-radius: 10px;
    padding: 1em 1em;
    height: calc(100% - 26%);
    max-height: calc(100% - 26%);
    overflow-y: auto;
}

.title{
    color: #FFF8F8!important;
    font-size: 20px!important;;
    font-weight: normal!important;
}



.input-access{
    background: #494A4D;
    border-radius: 8px;
    border: 1px solid #494A4D !important;
    /*border-radius: 16px;*/
}

.input-access input {
    background: rgba(0,0,0,0.0);
    width: 100%;
    color: white;
    padding: 0.5em!important;

    margin-left: 7px;
}


.filters{
    text-align: end;
}

.list-item-access{
    width: 100%;
    min-height: calc(100vh / 2 + 90px);
    overflow-y: auto;
}
.list-header{
    color: #FFF !important; 
    font-weight: 700;
    font-size: 13pt;
    text-align: center !important;
    background: transparent!important;
    margin-bottom: 10px;
    transition: background 0.3s ease;
    padding: 0;
}
.list-header span{
    color: #fff;
}

.ant-list-item{
    border:none!important;
    padding-bottom: 0;
    /* padding-top: 0.3em; */
    padding:5px 0px 0px 0px!important;
}


.list-item-access .item-access-row {
    background: #171819;
    border-radius: 8px;
    width: calc(100% - 2em);
    border: none!important;
    position: relative;
    min-height: 52px;
    margin: 0px auto!Important;

}

.list-item-access .item-access-row .muesca{
    position: absolute;
    left: 1px;
    top: 6px;
    width: 4px;
    height: 78%;
    background: #04FAA1;
}


.list-item-access .item-access-row-column {
    color: #899AA4;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}


.list-item-access .item-access-row-column span{
    color: #899AA4;
}

.list-item-access .item-access-row:hover span,
.list-item-access .item-access-row-column span:hover{
    color: #899AA4;
}

.list-item-access .item-access-row-column:nth-child(6){
    width: 10% !important;
    text-align: end;
}

.ver-detalles{
    font-size: 12px;
    color: #FFF!important;
}


.table-requests-container{
    min-height: calc(100vh - 27vh);
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
}


.list-item-request{
    width: 100%;
}

.pagination-content .ant-pagination-item > * {
    background: #171819;
    border: none;
    color: white!important;
    font-family: 'Rubik', sans-serif;
}

.pagination-content .ant-pagination-item-link {
    background: #171819 !important;
    color: white!important;
}

.pagination-content{
    position: absolute;
    right: 0px;
    margin-bottom: 15px;
    bottom: -64px;
}


.modal-detAccesos{
    border-radius: 15px !important;
    background: linear-gradient(94.6deg, #32383E -60.81%, #17191C 185.78%) !important;
    box-shadow: -5px -6px 16px rgba(195, 200, 205, 0.04), 6px 6px 28px rgba(0, 0, 0, 0.3) !important;
    max-width: 750px !important;
    margin-top: 0px !important;
    top: 40px !important;
    max-height: none !important;
}

.modal-detAccesos .ant-modal-close{
    position: relative !important;
    float: left !important;
    margin-top: 5px;
    margin-bottom: -60px;
}

.modal-detAccesos .ant-modal-close-icon{
  display: none !important;
  visibility: hidden !important;
}

.modal-detAccesos .ant-modal-body{
    display: block !important;
    position: relative !important;
    float: left !important;
    width: 100% !important;
}

.modal-detAccesos .tituloModal{
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 20px;
    /* or 57% */
    letter-spacing: 0.1px;
    color: #04FAA1;
    margin: auto;
    min-height: 45px;
    text-align: center;
    padding: 12px 0px;
    margin-bottom: 15px;
}

.modal-detAccesos .row-info {
    overflow: auto !important;
    max-height: 755px;
}

.row-info .ant-row {
    width: 100%;
    position: relative;
    padding: 0px 20px;
}

.row-info .ant-col {
    margin-bottom: 28px !important;
}

.row-info .ant-col p,
.row-info .ant-col > span
{
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #899AA4 !important;
    margin-right: 5px;
    margin-bottom: 3px;
    margin-top: 15px !important;
}

.row-info .ant-col p

{   
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #899AA4 !important;
}


.row-info .ant-col label{
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 20px;
}


.modal-detAccesos .svg-IconCopy,
.modal-detAccesos .svg-IconClip{
    margin-left: 5px;
    margin-bottom: -4px;
}



.row-cards-accesos{
    width: 100%;
}

.row-cards-accesos .ant-col{
    padding-right: 10px;
    padding-bottom: 10px;

}

.card-access {
    background: #282c31;
    border-color: #282c31 ;
    border-radius: 15px !important;
    width: 100% !important;
    padding: 1rem;
    min-width: 270px !important;
    border: none !important;
    overflow: hidden;
    background: transparent !important; 
}

.card-access .ant-card-body{
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
    background: #282d32 !important;
}

.card-access .svg-IconAccess{
    margin-left: 2rem;
}

.card-access h1{
    font-size: 18px !important;
    color: #9ea7ac !important;
    font-weight: 500;
    text-align: center;
    margin-left: 10px;
    width: 70%;
}


.card-access .ant-card-body{
    padding:10px
}


/*Css para header de la tabla de Accesos*/
.row-enc-accesos .title{
    font-size: 22px !important;
    margin: 0;
}

.input-search 
{
    background: #494A4D !important;
    border-radius: 8px;
}

.input-search  .ant-input
{
    background: #494A4D;
    color: #9EA7AC;
}

.input-search  .ant-input-affix-wrapper
{
   background: transparent !important;
   border: none;
   padding: 0 0 0 10px;
}

.input-search .ant-input-group-addon{
    display: none;
}

.input-search  .ant-input::-webkit-input-placeholder
{
    color: #9EA7AC !important;
}

.input-search  .ant-input:-ms-input-placeholder
{
    color: #9EA7AC !important;
}

.input-search  .ant-input::placeholder
{
    color: #9EA7AC !important;
}

 .filters-drop  .ant-select-arrow ,
.row-enc-accesos  .ant-input-suffix {
    display: none !important;
    visibility: hidden !important;
}

.row-enc-accesos .ant-picker-range{
    border-radius: 0px !important;
    height: 15px !important;
    min-height: 35px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;  
    box-shadow: none !important;
    background: transparent;
    border: solid 1px #33F898 !important;
}

.row-enc-accesos  .ant-picker input::-webkit-input-placeholder{
    color: #33F898 !important;
}

.row-enc-accesos  .ant-picker input:-ms-input-placeholder{
    color: #33F898 !important;
}

.row-enc-accesos  .ant-picker input,
.row-enc-accesos  .ant-picker input::placeholder,
.row-enc-accesos .ant-picker .anticon-swap-right,
.row-enc-accesos .ant-picker .anticon-calendar{
    color: #33F898 !important;
}

.row-enc-accesos .ant-picker-range .ant-picker-clear{
    background: #24282c;
    right: 10px;
}

.row-enc-accesos .ant-picker-range .ant-picker-clear .anticon{
   color: #33F898;
}


 
.filters-drop .ant-select-selector{
   background: transparent;
}

.filters-drop .ant-select-selection-search,
 .row-enc-accesos .ant-select-selector{
    border-right: 0px;
    border: none !important;
}

.row-enc-accesos .ant-select-selection-item{

    padding: 0px !important;
}


.filters-drop .ant-select:not(.ant-select-disabled):hover .ant-select-selector{
    border-right-width: 0px !important;
}
.row-enc-accesos .ant-select-selection-item,
.row-enc-accesos .ant-select-selection-placeholder {
    color: #33F898 !important;
    opacity: 1 !important;
}




.svg-BtnPlus{
    background: red !important;
    /* Group 125 */
 

/* Ellipse 1 */
 

background: linear-gradient(126.63deg, #3FFCA0 11.98%, #00E676 83.35%);
box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.35), 0px 4px 4px rgba(0, 0, 0, 0.25);


/* Vector */
 
background: #FFFFFF;

}


.form-crearAcceso .ant-input-number,
.form-crearAcceso  input,
.form-crearAcceso .ant-picker   
 {
    color: #899aa4 !important;
    font-size: 12pt !important;
    font-weight: 300 !important;
    padding: 10px !important;
    border: solid 0px #171819 !important;
    min-height: 45px !important;
    width: 99% !important;
    max-height: 85px !important;
    box-shadow: inset -4px -2px 8px #575757, inset 4px 2px 8px rgba(13, 13, 13, 0.48) !important;
    border-radius: 20px!important;
    background: #222325 !important;
    border-radius: 50px !important;
}
 
.form-crearAcceso .ant-picker-input  

 {
    border-radius: 50px !important;
    background: #222325 !important;
    box-shadow: inset -4px -2px 8px #575757, inset 4px 2px 8px rgba(13, 13, 13, 0.48) !important;


}

.form-crearAcceso .ant-form-item{
    margin-bottom: 5px !important;
}



.form-crearAcceso .ant-col{
    margin-bottom: 10px !important;
    padding: 0px !important;
}


.form-crearAcceso .ant-form-item-control-input-content{
    height: auto !important;
    max-height: 200px !important;
    overflow: hidden;
}


.form-crearAcceso .ant-input-number ,
.form-crearAcceso .ant-picker.input-form{
    padding: 0px !important;
}

.form-crearAcceso .ant-picker.input-form{
    background: transparent !important;
    border: none;
}


.form-crearAcceso .ant-input-number input,
.form-crearAcceso .ant-picker-input input{
    box-shadow: none !important;
    background: transparent !important;
    border: none !important;

}


.form-crearAcceso .ant-picker-suffix
{
    margin-right: 10px !important;
}


.form-crearAcceso .ant-select-selector
{
    overflow: hidden !important;
}


.form-crearAcceso .dropzone-element{
    text-align: center;
    font-weight: bolder !;
}


.form-crearAcceso .ant-select-arrow{
    color:#12a875 !important
}


.form-crearAcceso  .ant-form-item-label > label.ant-form-item-required::before {
  display: none !important;
}

.row-info .form-crearAcceso  .ant-form-item-label {
    margin-left: 20px !important;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    margin-bottom: 10px !important;
    color: #899AA4 !important;
  }
  

.form-crearAcceso .item-input-clave,
.form-crearAcceso .item-input-clave.ant-form-item-has-error
{
    width: 70% !important;
    max-width: 450px !important;
    margin: 15px auto !important;
    padding: 0px !important;
    margin-left: 18% !important;
}


.form-crearAcceso .ant-form-item-control-input .ant-input-affix-wrapper.input-clave{
    padding: 4px 12px !important;
}

.form-crearAcceso .input-clave input{
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
}


.form-crearAcceso .btn-green{
    width: auto !important;
    height: auto !important;
    border: 1px solid transparent !important;
    background: linear-gradient(  88.33deg , #00E676 -7.64%, #00E676 145.94%) !important;
    box-shadow: -6px -6px 5px rgb(255 255 255 / 5%), 6px 8px 15px rgb(40 173 69 / 35%) !important;
    border-radius: 8px !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    color: #fff !important;
    text-align: center !important;
    float: left !important;
    margin: 25px auto !important;
    min-width: 150px !important;
    margin-left: calc(50% - 80px ) !important;
}





.pagination-table .ant-select-selector {
    background: transparent !important;
}

.pagination-table .ant-select-selector .ant-select-selection-search{
    border-right: none !important;
}
.pagination-table  .ant-select-selector *
{
    color: #33f898;
} 
 
.Dashboard {
  height: 99% !important;
}


/* input auto fill input style */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
{
    -webkit-box-shadow: 0 0 0 50px #07a3a1 inset !important;
    padding-left: 5px!important;
    border-radius: 20px!important;
}
:root {
	--aide-gray: #282c31;
	--aide-green:  #04FAA1;
}

.center{
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.top_up{
	top: 50px !important;
}

/*CSS GENERAL PARA LOS MODALES*/

.modal-aide .ant-modal-content{
	background-color: #282c31;
	background-color: var(--aide-gray);
	border-radius: 12px;
}

.modal-aide .ant-modal-body{
	padding-bottom: 3rem;
}

.modal-aide .modal-header{
	position: relative;
	min-height: 38px;
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.modal-aide .btn-close-modal,
.modal-aide .btn-close-modal:hover,
.modal-aide .btn-close-modal:focus{
	position: absolute;
	background: transparent;
	padding: 0;
	top: 1px;
	left: 0;
	height: 30px;
	border: none;
}

.logo-aide-modal{
	width: 100px;
}

.modal-aide .modal-title{
	color: #04FAA1;
	color: var(--aide-green);
	font-family: Rubik;
	font-style: normal;
	font-weight: 500;
	font-size: 35px;
	letter-spacing: 0.1px;
	margin: 1rem;
	text-align: center;
}

.modal-aide .modal-sub-title{
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	text-align: center;
	color: #899AA4;
	margin-bottom: 1rem;
}

.modal-aide .btn-green,
.modal-aide .btn-green:hover,
.modal-aide .btn-green:active,
 .modal-aide .btn-green:focus{
	background: linear-gradient(88.33deg, #00E676 -7.64%, #00E676 145.94%);
	box-shadow: -6px -6px 16px rgba(255, 255, 255, 0.1), 6px 9px 40px rgba(40, 173, 69, 0.54);
	border-radius: 8px;
	min-width: 150px;
	font-weight: 500;
	letter-spacing: 0.2px;
	border: none;
	margin-top: 1rem;
}

/*CSS PARA LOS INPUTS DENTRO DE  LOS MODALES*/

.modal-aide .input-prefix .ant-input,
.modal-aide .input-prefix .ant-input:hover{
	background: transparent !important;
	padding-left: 6px !important;
	font-size: 15px;
}

.modal-aide .ant-form-item-explain{
	margin: 0;
}

/*inputs tipo texto*/
.modal-aide .input-modal{
	background: #222325 !important;
	box-shadow: inset -4px -2px 8px #575757, inset 4px 2px 8px rgba(13, 13, 13, 0.48);
	border-radius: 20px;
	height: 32px;
}


.ant-picker {
 border-color:transparent !important;
 border: 1px solid transparent !important;
}

/*selects*/
.modal-aide .select-modal .ant-select-selector{
	background: linear-gradient(210.55deg, #353A40 -3.02%, #121416 93.08%) !important;
	box-shadow: inset -16px -6px 80px rgba(248, 249, 249, 0.03);
	border-radius: 24px !important;

	background: linear-gradient( 210.55deg , #121416 -3.02%, #353A40 93.08%) !important;
}

.modal-aide .select-modal .ant-select-selection-search{
	border-right: none;
}


.modal-aide .subtitle-modal{
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
	color: #899AA4;
	margin-left: 15px;
}
.modal-aide .modal-text{
	font-weight: 400;
	font-size: 18px!important;
	color: #899AA4;
	word-wrap: break-word;
}



.modal-aide .select-modal.select-large .ant-select-selector{
	height: 45px !important;
}

.modal-aide .select-modal.select-large .ant-select-selection-placeholder,
.modal-aide .select-modal.select-large .ant-select-selection-item{
	padding-top: 8px;
}

.modal-aide .select-modal .ant-select-selector:hover,
.modal-aide .select-modal *{
	border: none !important;
	border-right-width: 0px !important;
}


.modal-aide .ant-select,
.modal-aide .ant-select-selector,
.modal-aide input {
	min-height: 42px;
	margin-top: 0px;
}


.buttons-container{
	grid-row-gap: 0px !important;
	row-gap: 0px !important;
	-webkit-flex-flow: unset !important;
	        flex-flow: unset !important;
	display: unset !important;
}

.btn- {
	font-size: 1.05em !important;
	border: none !important;
	margin: 6px;
	padding: 0.7em 2em !important;
	height: auto !important;
}


.btn-accept{
	background: #2A9F9E !important;
	box-shadow: 6px 9px 40px rgba(42, 159, 158, 0.37) !important;
	border-radius: 8px !important;
	float: right;
}

.btn-denied{
	background: #C51F29 !important;
	box-shadow: 6px 9px 40px rgba(255, 0, 0, 0.25)  !important;
	border-radius: 8px  !important;
}

.border{
	border: solid 1px red !important;
}

.border2{
	border: solid 1px blue;
}
/*
    #414d55
    #131414
*/

.darkTheme{
    background: transparent !important;
}

.darkTheme .ant-picker-panel {
    background: #131414 !important;
}

.div-calendario
{
    padding: 10px;
    background: transparent !important;
    border-radius: 16px;
}

.ant-picker-calendar .ant-picker-panel .ant-picker-body{
    padding: 0px !important;
}

.darkTheme  .ant-select-arrow {
    color:#90a1ac;
 }

 .darkTheme .ant-radio-button-wrapper
 {
     background: transparent !important;
     color:#90a1ac;

 }

 .darkTheme   .ant-radio-group  
 {
     background: transparent !important;
 }

 .darkTheme  .ant-radio-group > label
 {
     background: #414d55 !important;
     color:#90a1ac;
     border:none ;
     border-radius: 5px;
     margin-left: 10px;
 }

 .darkTheme  .ant-radio-button-wrapper:not(:first-child)::before {
     background: transparent !important;
 
 }

 .darkTheme  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child,
 .darkTheme  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
     z-index: 1;
     color: #2a9f9e !important;
     background: #fff;
     box-shadow: -1px 0 0 0 #2a9f9e !important;
 }

 .darkTheme  .ant-picker-content  thead {
     background: #090a0a;
     text-align: center;
 }
 
 .darkTheme  .ant-picker-content  th {
     background: #090a0a;
     color:#9ea7ac;
     padding: 15px !important;
     text-align: center !important;
     border:solid 1px #232425;
     font-size: 15pt;
     
 }

 

 .darkTheme  .ant-picker-content thead th:first-child{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.darkTheme  .ant-picker-content thead th:last-child{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.darkTheme  .ant-picker-content td{
    border:solid 1px #232425; 
}

.darkTheme  .ant-picker-content .ant-picker-calendar-date, 
.darkTheme  .ant-picker-content .ant-fullcalendar-date {
    border-top: none;
}

.darkTheme  .ant-picker-content  .ant-picker-calendar-date-value{
    color:#9ea7ac !important;
    font-size: 18px;
    text-align: left !important;

}
 
.darkTheme .ant-badge-status-text,
.darkTheme  .notes-month
{
     color:rgb(233, 233, 233);
 }

 .darkTheme  .ant-picker-content .ant-picker-calendar-date:hover, 
.darkTheme  .ant-picker-content .ant-fullcalendar-date {
    background: #2a9f9e !important;
    background: #589b9a !important;
    background: #0f3837 !important;
}

 
.darkTheme .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date, 
.darkTheme .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date,
.darkTheme .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today, 
.darkTheme .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today 
{
    background: #1f2325 !important;
    
}
.ant-picker-calendar-header {
    display: block;
}

.ant-picker-calendar-header .ant-picker-calendar-year-select,
.ant-picker-calendar-header .ant-picker-calendar-month-select
{
    min-width: 180px !important;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
    height: 65px;
    display: inherit !important;
}

.contenedor-etiquetas .divNivel{
    min-width: 100px !important; 
}

.radioCalFiltro{
    background: transparent !important;
}

.radioCalFiltro .ant-radio-button-wrapper ,
.radioCalFiltro .ant-radio-button-wrapper:not(:first-child)::before 
{
    border-left: 1px solid #555 !important;
    background-color: transparent;
}

.radioCalFiltro label{
    background: transparent !important;
    color:#AAA;
    border-color: #555;
    font-weight: 400;
    font-size: 12pt;
    margin: 3px 5px;
}


.radioCalFiltro .ant-radio-button-wrapper:hover {
    
    position: relative;
    color: #589b9a;
}

.radioCalFiltro .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
.radioCalFiltro .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #2a9f9e !important;
    border-color:  #2a9f9e !important;
    background: #2a9f9e;
}

.radioCalFiltro label{
    background: transparent !important;
    color:#AAA;
    border-color: #555;
    font-weight: 400;
    font-size: 12pt;
    margin: 5px 5px;
}


.lvlTodos svg {color: teal;}
.lvlDenegados svg {color: #fa8c16;}
.lvlCompletados svg {color: #52c41a;}
.lvlPendientes svg {color: #1890ff;}
.lvlError svg {color: #ff4d4f;}


.lvlAprobada svg {color: #1890ff;  margin-left: 5px;}
.lvlSolicitada svg {color: #FDCA40;  margin-left: 5px;}
.lvlDenegada svg {color:#d60420;  margin-left: 5px;}
.lvlTodas svg {color: #00E676; margin-left: 5px;}


 
.modal-detCalendario{
    background: linear-gradient(94.6deg, #32383E -60.81%, #17191C 185.78%);
    padding-bottom: 0px;
}

.modal-detCalendario .ant-modal-body {
    padding: 0px;
}
.modal-detCalendario .ant-modal-content {
    height: 100%;
    background:transparent!important;
    overflow: auto;
}

.modal-detCalendario .anticon-info-circle {
    display: none;
    visibility: hidden;
}


.modal-detCalendario .ant-modal-confirm-title {
    background-color: #171819;
    color: #FFF;
}

.modal-detCalendario .ant-modal-confirm-title h2 {
    color: #CCC;
    margin-left: 10px;
    padding: 10px 0px 0px 10px;
    font-size: 15pt;
}

.modal-detCalendario .ant-modal-close {
    display: block !important;
    color: #cccccc;
}



.modal-detCalendario .ant-modal-confirm-content .ant-row {
    max-height: 70vh;
    overflow: auto;
}

.modal-detCalendario label {
    color: #DDD !important;
    font-size: 13pt;
}

.modal-detCalendario p {
    color: #999 !important;
    font-size: 11pt;
}

.modal-detCalendario .ant-modal-confirm-btns {
    padding: 0px 15px 15px 0px;
}


.modal-detCalendario .ant-btn-primary {
    background-color: teal;
    border-color: teal;
}


/*Otros*/
.calendario-layout .dashboard-section-header,
.calendario-layout .divForm
{
    background: transparent !important;
    box-shadow: none !important;
}

.calendario-layout .contenedor-etiquetas .divNivel {
 border: none !important;
}

.calendario-layout .radioCalFiltro .ant-radio-button-wrapper:not(:first-child)::before {
    border-left: none!important;
    
}

.span-calendar{
    background: #1890ff;
    padding: 2px 10px;
    border-radius: 10px;
    color: white;
}

.calendario-admisiones .ant-picker-calendar-date-content{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}

.calendario-admisiones .ant-radio-group{
    display: none;
}

.calendario-admisiones .ant-select-arrow{
    left: 90% !important;
}
.avatar-group-card .ant-avatar-string{
    margin-top: 15px;
}

/*para los card de project manager*/
.avatar-info-card .ant-avatar-string{
    margin-top: 5px;
}

.header{
  display: -webkit-flex;
  display: flex;
  width: 100vw !important;
  max-width: 100% !important;
  height: 96px;
  background: linear-gradient(94.6deg, #32383E -60.81%, #17191C 185.78%)!important;
  box-shadow: -5px -6px 16px rgba(195, 200, 205, 0.04), 6px 6px 28px rgba(0, 0, 0, 0.3);
 

-webkit-filter: drop-shadow(-6.22302px -6.22302px 18.6691px #3B4451) drop-shadow(6.22302px 6.22302px 18.6691px #000000) !important;
 

        filter: drop-shadow(-6.22302px -6.22302px 18.6691px #3B4451) drop-shadow(6.22302px 6.22302px 18.6691px #000000) !important;
background: #2C2F33 !important;
box-shadow: -5px -6px 8px rgba(195, 200, 205, 0.05), 
              6px 6px 5px rgba(0, 0, 0, 0.5) !important;


}

.header-content{
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  float: right;
  min-height: 50px;
  

}

.header-logo{
  height: 110px;
}

.header-logo .logo{
  margin: 15px;
  height: auto;
  max-width: 350px;
}


.header-options{
   display: -webkit-flex;
   display: flex; 
  /* width: 250px; */
  margin-right: 20px;
  -webkit-align-items: center;
          align-items: center;
  vertical-align: middle;
  height: 100%;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.header-options div{
  width: auto !important;
  display: inline-block;
}
.IconoBell {
  color:#2fb29d; 
  font-size:32px;  
  /* position: relative; */
  top: 10px;
}
.IconoBell svg{
  margin-top: 10px;
}

.Menu{
  float: left;
}

.Header .ant-badge-count, .ant-badge-dot, .ant-badge .ant-scroll-number-custom-component{
  top: 8px;
  right: 14px;
}


.notifications-list{
  min-width: 250px;
  max-height: 345px;
  overflow: auto;
  border-bottom: 1px solid #eaeaea;
  background:  linear-gradient(94.6deg, #32383E -60.81%, #2c2f33 185.78%)!important;
  background:  #171819  !important;
}


.notifications-list .ant-menu-item a{
  color: #33F898 ;
  margin-left: 5px;
}


.notification-element{
  border-bottom: 1px solid #d6d6d6;
  height: auto!important;
}

.reciente{
  position: absolute;
  top: 13px;
  right: 0px;
}

.floating-button {
    position: fixed;
    right: 2em;
    bottom: 2em;

    width: 70px !important;
    min-width: 70px !important;
    height: 70px !important;

    background: linear-gradient(126.63deg, #3FFCA0 11.98%, #00E676 83.35%);
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.35), 0px 4px 4px rgba(0, 0, 0, 0.25);

    border: none;

    
    transition: all 0.2s ease-in;
    -webkit-animation: all 0.2s ease-in;
            animation: all 0.2s ease-in;

    border-radius: 100%;
}


.floating-button:hover{
    background: linear-gradient(126.63deg, #3FFCA0 11.98%, #00E676 83.35%);
    box-shadow: 0px 0px 9px 2px rgba(255, 253, 253, 0.55), 0px 4px 4px rgba(0, 0, 0, 0.25);

    font-size: 1em;

    width: 35px;
    border: none;
}


.floating-button svg{
    width: 35px;
    position: relative;
    top: 3px;
}
.modal-detAccesos .lblTitleItemForm{
    margin-left: 20px !important;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #899AA4 !important;
    margin-bottom: 10px !important;
}

.modal-detAccesos .btn-form{
    padding: 15px !important;
    text-align: center !important;
    float: left !important;
    
}


.modal-detAccesos .btn-verde{
    width: auto !important;
    height: auto !important;
    border: 1px solid transparent !important;
    background: linear-gradient( 88.33deg , #00E676 -7.64%, #00E676 145.94%) !important;
    box-shadow: -6px -6px 5px rgb(255 255 255 / 5%), 6px 8px 15px rgb(40 173 69 / 35%) !important;
    border-radius: 8px !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    color:#fff !important;
    text-align: center !important;
    float: left !important;
    margin: 25px auto !important; 
    min-width: 150px !important;
    margin-left: calc(50% - 80px ) !important;
}

.register-form .select-form{
    background: linear-gradient(210.55deg, #121416 -3.02%,  #353A40  93.08%);
    box-shadow: inset -16px -6px 80px rgba(248, 249, 249, 0.03);
    border-radius: 24px;
    height: 45px !important;
    padding-top: 8px !important;
}



/*.register-form  .ant-select-arrow ,*/
.register-form  .ant-input-suffix 
{
    display: none !important;
    visibility: hidden !important;
}

 

.register-form .ant-select-selection-search {
    border: none !important;
}

.register-form .svg-IconSelectArrowModal{
    position: inherit !important;
    display: block !important;
    margin-top:-73px !important;
    margin-right: 29px !important;
    z-index: 10 !important;
    transition-delay: 0s !important;
    float: right !important;
}


.register-form  .ant-form-item-has-error{
    margin-bottom: 0px !important;
}


.register-form  .ant-form-item-has-error .ant-select-selector > span.ant-select-arrow {
    display: none !important;
    visibility: hidden !important;
}

.register-form .ant-col{
    overflow: hidden !important;
}

.register-form .ant-input-prefix .anticon{
    color:#899AA4 !important; 
    margin-right: 5px !important;
}

.btnAddPlus{
    background: #12e764 ;
    width:  37px;
    height: 37px;
    border-radius: 5px;
    padding: 0px;
    font-weight: bolder;
    border: solid 3px #12e764 !important;
    color:#FFF;
    float: right;
    cursor: pointer;
}

.filter-form{
    width:calc(100% - 20px) !important;
    min-width: 80px;
    max-width: 600px;
    float:left;
}

.encabezado{
    margin-bottom: 50px;
    font-size: 25px!important;
}

.contenido{
    width: 100%;
    float: left;
}

.UserRegister .ant-table-content{
    background: rgba(255, 255, 255, 0) !important;
    background: #2a2b2d !important
}

.tablaUsuarios  th {
    color:#FFF !important;
    font-weight: 700;
    font-size: 13pt;
    text-align: center !important;
    background: transparent !important;
    transition: background 0.3s ease;
    border-bottom: solid 2px #FFF;
    margin-bottom: 10px;
}
.tablaUsuarios .ant-table-tbody  {
   overflow: auto !important;
}
 

.tablaUsuarios .ant-table-tbody > tr > td {
    
    font-size: 11pt;
    text-align: center;
    border:none !important;
    background: #171819;
    border-bottom: solid 5px  #2a2b2d !important;
    padding:3px;
    min-height: 60px;
    height: 60px;
    max-height: auto;
}
.tablaUsuarios .ant-table tbody > tr.ant-table-row:hover > td{
    background: #589b9a !important;
    color:#FFF;
}
.tablaUsuarios tbody td:first-child{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.tablaUsuarios tbody td:last-child{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}


.tablaUsuarios .ant-table-tbody > tr:first-child td{
    border-top: solid 5px  #2a2b2d !important;
    
}
 
.spnAccionesUsu a
{
    margin: 5px;
    background: #000;
    border-radius: 5px;
    border: solid 1px #171819;
    padding:2px;
    width: 34px !important;
    height: 34px !important;
    float: none;
    float: initial;
    display: -webkit-inline-flex;
    display: inline-flex;
    box-shadow: inset -2.5px -2.5px 1px #3e3f3f, inset 1px 1px 0.5px #171819;
    
}

.spnAccionesUsu a svg
{
    width: 25px !important;
    height: 25px !important;
    padding:3px;
    color: #FFF;
    border-radius: 3px;
}

.RecurIcon svg{
    background: #52c41a;
    border: solid 1px #b5ff60;
}

.EditIconUsu  svg {
    background: #0084ff;
    border: solid 1px #4ca9ff;
}
 

.div-headerUsuarios{
    padding-bottom: 10px !important;
}

.table-permisos{
    color: white;
    font-size: 15px;

}

.tag-permiso{
    background: transparent;
    border:1px solid #fff;
    color: #fff;
}
.table-permisos-row{
    background: #171819;
    border: 2px solid rgba(255, 255, 255, 0);
    box-sizing: border-box;
    border-radius: 6px 6px;
    margin: 10px;
    font-weight: 700;
    font-size: 20px;
    min-height: 50px;
    padding-left:1rem 
}

.tag-permisos{
    background: transparent;
    color:#899aa4;
    border: 1px solid #899aa4;
}

.text-gray{
    color: #899aa4;
}

.table-permisos-tbody .ant-form-item,
.table-permisos-tbody .ant-switch{
    margin: 0;
}


 
.svg-IconSearch{
    position: inherit !important;
    display: block !important;
    top:30px !important;
    margin-left: 10px !important;
    z-index: 100 !important;
    transition-delay: 0s !important;
    float: left !important;
}


.row-enc-usuarios  .input-search input.ant-input{
    padding-left: 30px !important;
    background: transparent !important;
}

.row-enc-usuarios .input-search{
    background: transparent !important;
    padding: 0px !important;
    
}

.row-enc-usuarios h1 {
        font-weight: 400 !important;
        font-size: 28px !important;
        color: #9EA7AC !important;
        margin: 0px !important;
}

.modal-form{
    padding-bottom: 0px !important;
}


.modal-detAccesos.modal-form  .btn-form{
    padding: 0px !important;
    margin: 0px !important;
}

.modal-form .row-info .ant-col {
    margin-bottom: 20px !important;
}


.selec-niveles{
    min-width: 175px !important;
}

.selec-niveles .ant-select-selection-placeholder{
    text-align: right !important;
    padding-right: 20px !important;
}

.table-asuntos .ant-table-cell{
    text-align: start !important;
    padding-left: 15px !important;
}


.modal-asunto .modal-title{
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.1px;
    color: #04FAA1;
}


.modal-asunto .input-box{
    background: #222325 !important;
    padding: 1em;
    box-shadow: inset -4px -2px 8px #575757, inset 4px 2px 8px rgba(13, 13, 13, 0.48);
    border-radius: 20px!important;

}


.modal-asunto .submit-button{
    margin: 1em auto;
    display: block;
    background: linear-gradient(88.33deg, #00E676 -7.64%, #00E676 145.94%);
    box-shadow: -6px -6px 16px rgba(255, 255, 255, 0.1), 6px 9px 40px rgba(40, 173, 69, 0.54);
    border-radius: 8px;
}

.modal-asunto .form-item-content .ant-form-item-label{
    text-align: center;
    margin-top: 1em;
    margin-bottom: 1em;
}
.item-access-row-column{
    text-align: center !important;
}

.item-access-row-column .svg-IconEye {
margin-left: 5px ;
}


.item-db-row{
    height: auto !important;
}

.item-db-row .muesca{
    height: 90% !important;
}
/*Contenido*/
main {
    width: 100%;
    color: #FFF;
    background-color: #2C2F33;
}

.ant-layout{
    height: 100%;
}

.divLayout > .ant-layout-has-sider {
    background: #2C2F33 !important;
    min-height: 90vh !important;
    /* padding:25px !important; */
}

.divContenedorLayout{
    margin-left: 1rem;
    margin-right: 1rem;
}

/* layouts */
.dashboard-section-layout
{
    background:transparent !important ;
    border-radius: 16px;
    margin: 0px 1rem;
    overflow: hidden;
    margin-bottom: 1rem;
    
}

.dashboard-section-layout{
    padding: 25px 15px!important;
    margin: 0px !important;
}

.dashboard-section-page-header{
    background: #2a2b2d !important;
    
}
.dashboard-section-header{
    background: linear-gradient(94.6deg, #32383E -60.81%, #17191C 185.78%);
    border: none;
    padding: 5px 25px;
  
}
.dashboard-section-header > .ant-row{
    min-height: auto;
    
}
.dashboard-section-header-accesos{
    background: #2C2F33!important;
}
.dashboard-section-content{
    min-height: 100%;
    margin-top: -1px;
}

.nombreVista .ant-page-header-heading-left .ant-page-header-heading-title,
.nombreVista{
    text-align: left !important;
    font-family: Rubik !important;
    font-style: normal !important;
    font-weight: 500 !important;
    color: #9EA7AC !important;
    font-size: 28px;
    line-height: 40px;
    color: #9EA7AC;
    margin-bottom: 0px;
   } 

/*Menu*/
.trigger {
    float: left;
    margin: 20px;
    margin-left: 28px;;
    font-size: 25px;
    color: #FFF;
}

#components-layout-demo-custom-trigger.trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
}

#components-layout-demo-custom-trigger.logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
}


.border-bottom-none{
    border-radius: 0px!important;
}

/*Contenedores*/
.div-contenedor {
    padding: 10px !important;
    text-align: center;
    background: linear-gradient(94.6deg, #32383E -60.81%, #17191C 185.78%);
    box-shadow: -5px -6px 16px rgba(195, 200, 205, 0.04), 6px 6px 28px rgba(0, 0, 0, 0.3);
    border-radius: 16px;
    margin-bottom: 1rem;

 

background: linear-gradient(140.63deg, #2C2F33 0%, #414D55 153.32%) !important;
box-shadow: 14px 14px 40px rgba(16, 16, 18, 0.75), -7px -7px 30px #262E32 !important;
border-radius: 8px !important;



}

.div-ultimosAccesos{
    height: calc(100vh / 2);
    max-height: calc(100vh /2)!important;
    overflow-y: auto;
}
.container-notificaciones{
    height: 100vh;
    min-height: 100vh;
    max-height: 100vh;

}

.div-notificaciones{
    overflow-y: auto!important;
    width: 100%;
    min-height: calc(100% - 80px)!important;
    height: calc(100% - 80px)!important;
    max-height: calc(100% - 80px)!important;
}
.container-notificaciones> .div_titulo {
    height: auto !important;
    min-height: 80px;
}


.item-request-row{
    position: relative;
    padding: 0.5rem 1rem;
    background: #171819;
    border-radius: 8px;
    width: 100%;

}
.button-notificacion{
    background: #FAE104;
    color: #fff;
    top: -10px;
}
.button-notificacion:hover,
.button-notificacion:focus,
.button-notificacion:active{
    background:#FAE104;
    opacity: 0.8;
    color: #fff;
}
 .muesca{
    position: absolute;
    left: 1px;
    top: 6px;
    width: 4px;
    height: 85%;
    background: #FAE104;
}

 .request-title{
    font-size: 15px!important;
    color: #90A1AC!important;
}

 .request-description{
    font-size: 12px;
    color: #90A1AC;
    max-width: 80%;
}

.request-date {
    color: white;
    font-size: 12px;
    position: absolute;
    right: 8px;
    z-index: 112312;
    bottom: -1px;
}


.div-notificacion{
    background-color: #171819 !important;
    border-radius: 10px;
    border:none !important;
    box-shadow: none !important;
    min-height: 45px !important;
    height: auto;
    padding: 10px !important;
    padding-left: 5px !important;
    width: 99% !important;
    margin: 0px 0px 5px 0px !important;
}

.border-solicitud{
    position: relative;
    width: 3px;
    top: 18%;
    height: 40px;
    background: #FAE104 !important;
}
.border-notificacion{
    width: 3px;
    height: 40px;
    background:  #08e093 !important; 
}
.div-solAcceso {
    width: 100%;
    padding: 5px;
    height: 80px;
    height: auto;
    margin-bottom: 5px;
}

.div-solAcceso label {
    float: right;
    width: 98%;
    text-align: left;
}


.IconSquare {
    font-size: 42px;
    color: #2C2F33;
    border-radius: 10px;
    float: left;
}

/*Notificaciones*/
.div-nivelesNotificacion {
    float: left;
    width: 100%;
    height: auto !important;
    padding: 1px;
    background-color: #2C2F33 !important;
    border-radius: 10px;
    font-size: 12.5px;
    margin-top: 3px;
}

.divNivel {
    width: auto;
    float: left;
    padding: 5px;
    min-width: 25%;
    opacity: 1 !important;
    z-index: 100;
}

.divNivel > svg {
    font-weight: bold !important;
    color: transparent;
    font-size: 12px;
    border-radius: 100%;
    padding: 0px;
    border: solid 3px red;
    margin-right: 5px;

}


.nivel-a > .ant-btn,
.nivel-b > .ant-btn,
.nivel-c > .ant-btn,
.nivel-d > .ant-btn,
.nivel-e > .ant-btn {
    background: transparent;
    color: #FFF;
    border-color: #666;
}


.nivel-a > .ant-btn > svg {
    color: #34d1bf !important;
    margin-right: 5px;
    margin-top: 3px;
}

.nivel-b > .ant-btn > svg {
    color: #2662f0 !important;
    margin-right: 5px;
    margin-top: 3px;
}

.nivel-c > .ant-btn > svg {
    color: #0496ff !important;
    margin-right: 5px;
    margin-top: 3px;
}

.nivel-d > .ant-btn > svg {
    color: #ed0423 !important;
    margin-right: 5px;
    margin-top: 3px;
}

.nivel-e > .ant-btn > svg {
    color: #fdca40 !important;
    margin-right: 5px;
    margin-top: 3px;
}

.nivel-a > svg, .nivel-a > .ant-btn > svg {
    border-color: #34d1bf !important;
}

.nivel-b > svg, .nivel-b > .ant-btn > svg {
    border-color: #2662f0 !important;
}

.nivel-c > svg, .nivel-c > .ant-btn > svg {
    border-color: #0496ff !important;
}

.nivel-d > svg, .nivel-d > .ant-btn > svg {
    border-color: #ed0423 !important;
}

.nivel-e > svg, .nivel-e > .ant-btn > svg {
    border-color: #fdca40 !important;
}

/*Card*/
.ant-card {
    border-radius: 5px;
    padding: 10px;
    color: gray;
}

.ant-card-cover img {
    border-radius: 5px;
    max-height: 165px;
}

.ant-card-body {
    padding: 5px;
    zoom: 0;
}


/*Footer*/
.divFooter {
    background-color: #161718 !important;
    border-top: solid 1px #2C2F33;
    color: white !important;
    text-align: center;
    height: 30px;
    padding: 5px;
    font-size: 13px;
}


.div_titulo {
    top: 0;
    position: relative;
    min-height: 28px;
    font-size: 16px;
    text-align: left;
    color: #dee0e3;
    font-weight: 500;
    padding: 3px;
    height: auto;
    padding-bottom: 0px;
}

.div_contenido {
    min-height: calc(100% - 28px) ;
    height: calc(100% - 28px) !important;
    max-height: calc(100% - 28px);
    overflow: auto;
   
}


/*Calis*/
.div-principalContent {
    width: 99% !important;
    border-radius: 30px !important;
    float: left;
    position: relative;
    overflow: auto;
    height: 95%;
}


.nombre-solicitud
{
    color: #fff;
    float: left;
    margin-left: 15px;
}
.descripcion-solicitud{
    color: #fff;
    margin-left: -20px;
}
.fecha-notificacion{
    color: #9EA7AC;
    font-size: 10px;

}
.lblTitulo {
    font-weight: bold;
}

.container-notificaciones > .div_contenido {
    padding-right: 10px;
}
.list-full{
    max-height: calc(100vh - 140px);
    overflow-y: auto;
    height: calc(100vh - 140px);
}

.div-notificacion-item {
    background: transparent!important;
    border: none;
    border-radius: none !important;
    box-shadow: none;
    border-bottom: dashed 1px gray !important;
    width: 98% !important;
    margin: 0px !important;
    height: 100px;
    padding-top: 10px;
}
.notificacion-box{
    background: rgba(65, 77, 85, 0.8);
    height: 30px;
    width: 30px;
    border-radius: 5px;
}

.notificacion-nivel{
    font-size: 24px;
    color: #1C8DF7;
    background: rgba(65, 77, 85, 0.5);
    display: block;
    margin: auto;
    padding-top: 5px;
}
.notificacion-nombre{
    display: block;
    color: #9EA7AC;
    text-align: left;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;

}
.notificacion-fecha{
    display: block;
    text-align: left;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 20px;
    /* identical to box height, or 200% */
    letter-spacing: 0.416667px;
    text-transform: uppercase;
    color: #9EA7AC;

}
.divForm {
    height: 100%;
    text-align: left !important;
    padding: 10px;
    background: linear-gradient(94.6deg, #32383E -60.81%, #17191C 185.78%);
    box-shadow: -5px -6px 16px rgba(195, 200, 205, 0.04), 6px 6px 28px rgba(0, 0, 0, 0.3);
}

.divForm form{
    padding: 0px 1rem;
}

/*BOTONES*/
.btn, .btn-negro {
    float: right !important;
    text-align: center !important;
    border: none !important;
    min-width: 140px !important;
    margin-left: 10px;
    margin-bottom: 5px;
    border-radius: 5px;
    /* box-shadow:  0 1px 8px #ffffff80; */
}

.btn-azul {
    background: #2662f0 !important;
}

.btn-rojo {
    background: hsl(356, 73%, 45%) !important;
}

.btn-morado {
    background: #6c6ff0 !important;
}

.btn-tema {
    background: #2a9f9e !important;
}

.btn-negro {
    background: #2a2b2d !important;
    color: #FFF;
}

.btn-azul:hover {
    background: #4b79e4 !important;
}

.btn-rojo:hover {
    background: #c24b53 !important;
}

.btn-morado:hover {
    background: #8b8df5 !important;
}

.btn-tema:hover {
    background: #569c9b !important;
}

.btn-negro:hover {
    background: #569c9b !important;
}


.divBotones {
    height: auto !important;
    min-height: 10px !important;
}

.nView {
    visibility: hidden !important;
}

.div-Row {
    float: left;
    height: auto;
    width: 100%;
}


/*INPUTS*/
.ant-form-item-with-help {
    float: left;
    width: 100% !important;
}

.ant-form-item-with-help > .ant-form-item-control-wrapper {
    min-height: 65px;
    text-align: left;
}

.ant-form-item-control-input .ant-input-affix-wrapper,
.ant-form-item-control-input-content textarea,
.ant-input-search .ant-input,
.ant-picker {
    color: #899aa4;
    font-size: 12pt !important;
    font-weight: 300;
    padding: 10px !important;
    border: solid 0px #171819;
    min-height: 45px;
    width: 99%;
    max-height: 85px;
    background: linear-gradient(94.6deg, #32383E -60.81%, #17191C 185.78%);
    box-shadow: inset -4px -2px 8px #575757, inset 4px 2px 8px rgba(13, 13, 13, 0.48);
    border-radius: 20px!important;
    background: #222325 !important;
    height: 35px;
}

.input-filtro .ant-input-group-addon{
    background: transparent;
    padding-left: 1.5rem !important;
}

.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-select {
    border: solid 1px #8a3441 !important;
}


form input,
.ant-input-affix-wrapper,
.ant-select-selection,
.ant-select-selector {
    border-radius: 5px;
    border: 0px !important;
    text-align: left;
    color: rgba(255, 255, 255, 0.897) !important;
    font-size: 12pt;
    font-weight: 400 !important;
}

input::-webkit-input-placeholder {
    color: #565b5e !important;
    font-weight: 400;
    font-size: 11pt;
}

input:-ms-input-placeholder {
    color: #565b5e !important;
    font-weight: 400;
    font-size: 11pt;
}

input::placeholder {
    color: #565b5e !important;
    font-weight: 400;
    font-size: 11pt;
}


.ant-select-selection-placeholder {
    color: #919ba1 !important;
    padding-top:6px!important;
}

.ant-picker-input > input {
    color: #899aa4;
}

tr:hover input,
.ant-picker-input:hover > input {
    color: #FFF;
}

.ant-select-selector,
.ant-select-selector:hover,
.ant-form-item-has-error .ant-select .ant-select-selector,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector
.ant-select .input-form .ant-select-focused .ant-select-single .ant-select-allow-clear .ant-select-show-arrow .ant-select-open,
.ant-form-item-has-error .ant-select .ant-select-open .ant-select-selector,
.ant-form-item-has-error .ant-select .ant-select-focused .ant-select-selector {
    border-color: #ffffff00 !important;
    border: solid 0px !important;
    box-shadow: none !important;
}

.anticon-calendar,
.ant-select-arrow {
    color: #899aa4 !important;
    margin-right: 0px !important;
    cursor: pointer;
}

/*.ant-form-item-control-input:hover .ant-select .ant-select-selector,*/
.ant-select .input-form .ant-select-single .ant-select-allow-clear a.nt-select-show-arrow .ant-select-open:hover,
.ant-select .input-form .ant-select-single .ant-select-allow-clear .ant-select-show-arrow:hover,
.ant-form-item-control-input:hover .ant-select-focused .ant-select-selector,
.ant-form-item-control-input .ant-select-focused .ant-select-selector,
.ant-select .input-form .ant-select-single .ant-select-allow-clear .ant-select-show-arrow {
    border: 0px solid transparent !important;
}
.ant-input:focus, .ant-input-focused{
    outline: 0;
    border-color: none!important; 
     box-shadow: inset -4px -2px 8px #575757, inset 4px 2px 8px rgba(13, 13, 13, 0.48);
}
.ant-select-clear {
    display: inline-block;
    margin-top: -6px;
    color: #899aa4;
    color: #8a3441;
    font-size: 12pt;
    font-style: normal;
    padding-top: 0px;
    background: transparent !important;
    margin-right: 20px;
}



.ant-select-clear:hover {
    color: #c8464f;
}

.ant-switch {
    border-radius: 16px !important;
    height: 34px;
    width: 58px;
    margin-top: 6px;
    /* background: #171819; */
    border:  1px solid  #32383E;
    box-sizing: border-box;
    background: rgba(19, 20, 22, 0.2);
        box-shadow: inset 2px 2px 8px rgba(4, 4, 5, 0.6);
    /* box-shadow: inset -4px -2px 8px #575757, inset 4px 2px 8px rgba(13, 13, 13, 0.48)!important; */
}

.ant-switch-checked {
     background-color: #171819;
    box-shadow: inset 2px 2px 8px rgb(4 4 5 / 60%);
}

.ant-switch::after {
    background: #8ed1a7;
    border-radius: 3px !important;

}

.ant-switch-handle{
    top: 6px;
    
}
.ant-switch-checked .ant-switch-handle{
    left: calc(100% - 18px - 5px);
}

.ant-switch-checked::after {
    background: #0fd65a !important;
    border-radius: 3px !important;
}

.ant-switch-handle::before {
    background-color: #8ed1a7;
    border-radius: 25px;
}

.ant-switch-checked .ant-switch-handle::before {
    background-color: #0fd65a;
    background: linear-gradient(135deg, #16FF73 0%, #0FD75A 49.33%, #09AD41 100%);
    border: 2px solid rgba(255, 255, 255, 0.3);
    box-sizing: border-box;
}

.ant-form-item-explain {
    text-align: left;
    margin-bottom: 10px;
}

form .ant-form-item-label {
    float: left !important;
    text-align: left;

}

form .ant-form-item-label label {
    color: #899aa4;
    font-size: 11pt;
}

.ant-form-item > .ant-form-item-label {
    width: 100%;
}

.ant-select-dropdown {
    background: #161616 !important;
    background: #161718 !important;
    border: solid 0.5px #2a4b4a;
    width: auto;
    max-width: 500px;
    border-radius: 5px;
    padding: 5px;
    min-width: 100px !important;

}

.ant-select-dropdown
.ant-select-item-option {
    background: #161616 !important;
    background: rgba(68, 67, 67, 0.116) !important;
    border-left: solid 2px #589b9a;
    color: #899aa4;
    border-radius: 5px;
    margin-top: 5PX;
    margin-right: 5px;
}

.ant-select-dropdown
.ant-select-item-option-active {
    background: #161616 !important;
    background: #589b9a !important;
    background: #2a9f9e !important;
    border-bottom: solid 1.5px #18a19f27;
    border-bottom: solid 1.5px #07a3a1;
    border-left: solid 1px #161616;
    color: #FFF;
    border-radius: 5px;
    margin-top: 5PX;

}


/*Tablas*/
.div-scroll {
    overflow: auto !important;
}

.div-scroll > .div-contenedor {
    width: 97%;
    float: left;
}

.blackTheme {
    width: 100%;
}

.blackTheme .ant-table-content {
    background: rgba(255, 255, 255, 0) !important;
    background: #2a2b2d !important;
}
.blackTheme thead{
    background: linear-gradient(94.6deg, #32383E -60.81%, #17191C 185.78%)!important;
}
.blackTheme th {
    color: #FFF !important;
    font-weight: 700;
    font-size: 13pt;
    text-align: center !important;
    background: transparent!important;
    border-bottom: solid 2px #FFF;
    margin-bottom: 10px;
    transition: background 0.3s ease;
}

.blackTheme .ant-table-tbody {
    overflow: auto !important;
}

.blackTheme .ant-table-tbody > tr > td {
    color: #899aa4;
    font-size: 11pt;
    text-align: center;
    border: none !important;
    background: #171819;
    height: 60px;
    min-height: 60px;
    max-height: auto;
    border-bottom: solid 5px #2a2b2d !important;
    padding: 3px;
}



.ant-list .ant-row:hover .ant-col ,
.ant-list  .ant-row:hover .ant-col ,
.ant-table  .ant-table-row:hover td ,
.blackTheme  .ant-row:hover .ant-col ,
.blackTheme  .ant-row:hover .ant-col ,
.blackTheme .ant-table tbody > tr:hover > td 
{
    color: #b8cfdd !important;
}


.blackTheme .ant-table tbody > tr.ant-table-row:hover > td ,
.blackTheme .ant-table tbody > tr.ant-table-row:hover > td .tag-permisos,
.blackTheme .ant-table tbody > tr.ant-table-row:hover >  td >  a.acciont-text,
.blackTheme .ant-table tbody > tr.ant-table-row:hover > td >  a.acciont-text span,
.ant-list .ant-row:hover ,
.ant-table  .ant-table-row:hover ,
.blackTheme .ant-table tbody > tr:hover
{
    background: #589b9a !important;
    color: #DDD !important;
    background:  #04FAA1 !important;
    color: #fff !important;
}

.blackTheme td > a {
    font-size: 9pt !important;
    vertical-align: center;
    color: rgb(37, 138, 253);
}

.blackTheme tbody td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.blackTheme tbody td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.blackTheme .ant-table-tbody > tr:first-child td {
    border-top: solid 5px #2a2b2d !important;
}

.spnAcciones a,
.spnAccionesUsu a {
    margin: 5px;
    background: #171819;
    border-radius: 5px;
    border: solid 1px #171819;
    padding: 2px;
    width: 34px !important;
    height: 34px !important;
    float: none;
    float: initial;
    display: -webkit-inline-flex;
    display: inline-flex;
    box-shadow: inset -2.5px -2.5px 1px #3e3f3f, inset 1px 1px 0.5px #171819;

}

.spnAcciones a svg,
.spnAccionesUsu a svg {
    width: 25px !important;
    height: 25px !important;
    padding: 3px;
    color: #FFF;
    border-radius: 3px;
}

.EditIcon svg,
.EditIconUsu svg {
    background: #0084ff;
    border: solid 1px #4ca9ff;
}

.DeleteIcon svg,
.DeleteIconUsu svg {
    background: red;
    border: solid 1px #ff4c4c;
}

.icon-disabled ,
.btn-disabled,
.btn-disabled:hover{
    background: #8d8d8d71 !important;
    display: block;
    cursor: not-allowed !important;
}

.btn-disabled .icon-disabled {
    background: #fd5858 !important;
    border: solid 1px #ff4c4c !important;
    cursor: not-allowed !important;
}

.icon-disabled,
.icon-disabled:hover,
.icon-disabled:focus,
.icon-disabled:active
{
    cursor: not-allowed !important;
}

.ant-input-group-addon {
    padding: 0px;
    background: transparent;
}

.ant-input-search-button,
.ant-input-search-button:hover {
    /* display: none; */
    background-color: #2a9f9e !important;
    border-color: #2a9f9e !important;
    height: 45px !important;
    margin-left: -5px !important;
    border-radius:  20px 20px 20px 20px !important;
    border:none !important;
}

.ant-empty-description {
    color: #FFF;
}

.ant-table-cell:hover .ant-empty-description {
    color: gray
}


/* paginador */
.pagination-table {
    float: right!important;
    padding-top: 10px;
}
.pagination-table .ant-pagination-item > * {
    background: #171819;
    border: none;
    color: white!important;
    font-family: 'Rubik', sans-serif;
}
.pagination-table .ant-pagination-item-active *,
.pagination-table .ant-pagination-item:focus *,
.pagination-table .ant-pagination-item:hover *{
    border-color: #0f6160;
    background: #0f6160;
}

.pagination-table .ant-pagination-item-link {
    background: #2a9f9e !important;
    color: white!important;
    border-color: #2a9f9e;
}

.pagination-table li .ant-pagination-item-ellipsis{
    color: #fff;
}

.modalConfirm {
    z-index: 2000 !important;
    background: linear-gradient(94.6deg, #32383E -60.81%, #17191C 185.78%);
    opacity: 1;
    padding-bottom: 0;
}

.spanStatus {
    border-radius: 10px;
    padding: 0px 10px;
    color: #eee;
    width: auto !important;
    min-width: 65px;
    display: block;
}

.statusActivo {
    background-color: #2bd073;
}

.statusInactivo {
    background-color: #ff2222;
}

/* modales */
.modal-referencias{
    background: linear-gradient(94.6deg, #32383E -60.81%, #17191C 185.78%);
    padding-bottom: 0px;
}
.modal-referencias .ant-modal-header {
    background-color: #171819;
    border-bottom: none!important;
}

.modal-referencias .ant-modal-title,
.modal-referencias .ant-modal-close-icon {
    color: #CCC;
    font-size: 14pt;
}

.modal-referencias .ant-modal-body {
    overflow: auto;
}

.modal-referencias .ant-modal-content {
    height: 100%;
    background-color: transparent!important;
    overflow: auto;
}

.modal-referencias .ant-modal-body div {
    overflow: auto;
}

.modal-referencias .ant-modal-footer {
    display: none;
}

.modal-referencias {
    width: 80% !important;
    max-width: 850px;
    height: 65% !important;
    max-height: 800px;

}

.modal-referencias .ant-modal-title,
.modal-referencias .ant-modal-close-icon {
    color: #CCC;
    font-size: 14pt;
}

.ant-modal-confirm-confirm div{
    margin-left: 0px !important;
}

.ant-modal-confirm-confirm .ant-modal-content{
    background-color: transparent !important;
    background: linear-gradient(94.6deg, #32383E -60.81%, #17191C 185.78%) !important;
}

.ant-modal-confirm-confirm .ant-modal-content .ant-modal-body{
    padding:22px 22px 14px;
}

.ant-modal-confirm-confirm .ant-modal-content .ant-modal-confirm-body svg {display: none;}

.ant-modal-content  .IconoModal svg {display: block !important;}

.ant-modal-confirm-confirm .ant-modal-content .ant-modal-confirm-title{
    color: #FFF;
}

.ant-modal-confirm-confirm .ant-modal-content  .ant-modal-confirm-content{
    color: #899aa4;
}

.ant-modal-confirm-confirm .ant-modal-content  .ant-modal-confirm-btns .ant-btn{
    background: #ff4d4f !important;
    color: #fff !important;
    border-color: #ff4d4f !important;
}

.ant-modal-confirm-confirm .ant-modal-content  .ant-modal-confirm-btns .ant-btn-dangerous{
    background: #2a9f9e !important;
    color: #fff !important;
    border-color: #2a9f9e !important;

}

.overflow-x-null{
    overflow-x: hidden;
}


/* estilos genericos */
.border {
	border: solid 1px red;
}
.center{
	display: -webkit-flex !important;
	display: flex !important;
	-webkit-justify-content: center;
	        justify-content: center;
	-webkit-align-items: center;
	        align-items: center;
}

.flex-start{
    display: -webkit-flex !important;
    display: flex !important;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
}

.pd-1{
    padding: 1rem !important;
}
.pd-2{
    padding:2rem !important;
}
.pl-1{
    padding-left: 1rem !important;
}

.pr-1{
    padding-right: 1rem !important;
}
.pb-3{
    padding-bottom: 3rem !important;
}
.m-0{
    margin: 0 !important;
}
.pt-1{
    padding-top: 1rem;
}

.pb-1{
    padding-bottom: 1rem;
}
.ml-1{
    margin-left: 1rem !important;
}
.ml-2{
    margin-left: 2rem !important;
}
.ml-3{
    margin-left: 3rem !important;
}
.mt-1{
    margin-top: 1rem !important;
}
.mt-2{
    margin-top: 2rem !important;
}
.mt-3{
    margin-top: 3rem !important;
}
.mb-1{
    margin-bottom: 1rem !important;
}
.mb-2{
    margin-bottom: 2rem !important;
}
.mb-3{
    margin-bottom: 3rem !important;
}
.mr-1{
    margin-right: 1rem !important;
}
.mr-2{
    margin-right: 2rem !important;
}
.text-center{
    text-align: center;
}
.text-left{
    text-align: left;
}
.text-right{
    text-align: right;
}

.flex-left{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    -webkit-align-items: center;
            align-items: center;
}

/* Texts */
.accion-text, .accion-text span{
    color:#899AA4
}


/*Modal detalle*/
 .modalDetalle{
    background: linear-gradient(94.6deg, #32383E -60.81%, #17191C 185.78%);
}

.modalDetalle .ant-modal-header {
    background-color: #171819;
}

.mod.modalDetalle .ant-modal-title,
.mod.modalDetalle .ant-modal-close-icon {
    color: #CCC;
    font-size: 14pt;
}

.modalDetalle .ant-modal-body {
    overflow: auto;
    padding: 15px 25px;
    padding-bottom: 30px;
}

.modalDetalle .ant-modal-content {
    height: 100%;
    overflow: auto;
}


.modalDetalle .ant-modal-footer {
    display: none;
}

.modalDetalle {
    width: 80% !important;
    max-width: 500px;
    height: auto !important;
    max-height: 800px;
}

 .modalDetalle .ant-modal-body p strong{
     display: inline-block;
     margin-bottom: 5px !important;
 }


/*Button Add*/
.btnAddPlus{
    border: none !important;
    display:block !important;
    position:fixed !important;
    bottom:10px !important;
    right: 15px !important;
    z-index: 10 !important;
    width:  60px !important;
    height: 60px !important;
    border-radius: 100px !important;
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.35), 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    background: linear-gradient(126.63deg, #09eb60 11.98%, #09eb60 83.35%) !important; 
    padding: 0px !important;
    padding-top: 3px !important;
    padding-left: 2px !important;
}

.btnAddPlus:active,
.btnAddPlus:focus,
.btnAddPlus:hover{
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.45), 0px 4px 4px rgba(0, 0, 0, 0.35) !important;
    background: #09eb60;  
}

.addVisitante{
    width: 100%;
    border-radius: 5px !important; 
    border: none !important;
    margin-bottom: 5px;
    color: #fff!important;
    background: linear-gradient(126.63deg, #2A9F9E 11.98%, #2A9F9E 83.35%) !important; 

}
.addVisitante span{
  overflow-wrap: break-word;
}

/*Global nuevos estilos*/
 .input-search 
{
   width: 90% !important;
}

 
.input-search .ant-input-group-addon
{
   display: none !important;
}

.row-enc-vista .ant-col {
    margin-bottom: 10px !important;
}

.row-enc-vista .title{
    font-size: 22px !important ;
    margin: 0 !important;
    text-align: left !important;
}

.row-enc-vista  .input-search 
{
    background: #494A4D !important;
    border-radius: 8px !important;
    min-height: 10px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 40px;
}

.row-enc-vista  .input-search  .ant-input-prefix
{
    padding: 0px !important;
    margin-left: 0px;
    margin-right: 10px;
}

.row-enc-vista  .input-search  .ant-input-prefix svg
{
    padding: 0px !important;
    margin: 0px !important;
}

.row-enc-vista  .input-search  .ant-input
{
    background: #494A4D !important;
    color: #9EA7AC !important;
    background: transparent !important;
    border-color:transparent ;
    box-shadow: none !important;
}

.row-enc-vista  .input-search  .ant-input::-webkit-input-placeholder
{
    color: #9EA7AC !important;
}

.row-enc-vista  .input-search  .ant-input:-ms-input-placeholder
{
    color: #9EA7AC !important;
}

.row-enc-vista  .input-search  .ant-input::placeholder
{
    color: #9EA7AC !important;
}


.row-enc-vista  .input-search  input.ant-input
{
    padding: 0px !important;
    margin: 0px !important;
    max-height: none !important;
    min-height: 40px !important;
    height: 40px !important;
}

.row-enc-vista .filters-drop  .ant-select-arrow ,
.row-enc-vista  .ant-input-suffix,
.modal-aide  .ant-form-item-label > label.ant-form-item-required::before
{
    display: none !important;
    visibility: hidden !important;
}

.row-enc-vista .ant-picker-range{
    border-radius: 0px !important;
    height: 15px !important;
    min-height: 35px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;  
    box-shadow: none !important;
    background: transparent;
    border: solid 1px #33F898 !important;
}

.row-enc-vista  .ant-picker input::-webkit-input-placeholder{
    color: #33F898 !important;
}

.row-enc-vista  .ant-picker input:-ms-input-placeholder{
    color: #33F898 !important;
}

.row-enc-vista  .ant-picker input,
.row-enc-vista  .ant-picker input::placeholder,
.row-enc-vista .ant-picker .anticon-swap-right,
.row-enc-vista .ant-picker .anticon-calendar{
    color: #33F898 !important;
}

.row-enc-vista .ant-picker-range .ant-picker-clear{
    background: #24282c !important;
    right: 10px !important;
}

.row-enc-vista .ant-picker-range .ant-picker-clear .anticon{
   color: #33F898;
}
 
.row-enc-vista .filters-drop .ant-select-selector{
   background: transparent !important;
}

.row-enc-vista .filters-drop .ant-select-selection-search{
    border-right: 0px;
}

.row-enc-vista .filters-drop .ant-select:not(.ant-select-disabled):hover .ant-select-selector{
    border-right-width: 0px !important;
    background: transparent !important;
}

.row-enc-vista .ant-select-selection-placeholder {
    color: #33F898 !important;
    opacity: 1 !important;
}

.row-enc-vista .ant-select-selector{
    border: none !important;
}

.row-enc-vista .ant-select-selection-item{
    color: #32f797;
}

.ant-form-item .ant-select{
    border-radius: 50px !important;
}

.input-form {
    min-height: 45px !important;
}

.ant-modal  {
    padding-bottom: 0px !important;
}


.ant-select .ant-select-clear{
    right: 10px !important;
    margin-top: -4px !important;
}

.ant-select .anticon-close-circle svg {
    color: rgba(255, 0, 0, 0.85) !important;
}


.modal-aide .ant-modal-close-x{
    display: none !important;
    
}

.areaCamara{
    position: absolute;
    float: left;
}
 
.iconoCamaraFija{
    color:#F08532 ;
}


.iconoCamaraLPR{
    color:#75FB4C !important;
}


.card-descriptionMapa{
    background: #32383E !important;
    width: 250px;
    height: auto ;
    border-radius: 10px !important;
    border: 1px solid  #32383E !important;
}

.card-descriptionMapa .ant-card-bordered {
    border-radius: 10px !important;
    border: 1px solid  #32383E !important;
}

.card-descriptionMapa .ant-card-body { 
    background: transparent !important;
    border: none !important;
}


.row-descMapa{
    width: 100%;
    float: left;
}

.row-descMapa .ant-col{
    float: left;
    display: block;
    padding: 0px;
    margin-bottom: 5px;
}

.row-descMapa .ant-col  .ant-typography{
    color:#DDD !important;
    top: 12px;
    position: relative;
    float: right;
    text-align: left;
    width: 120px;
    font-weight: 600;
    font-size: 11pt;
    margin-left: 10px;
}


.ant-upload.ant-upload-select.ant-upload-select-text{
    width: 100%;
}

.avatar-uploader .ant-btn{
    background: #222325 !important;
    color: #FFF;
    border-radius: 10px;
    border:none !important;
    box-shadow: inset -4px -2px 8px #575757, inset 4px 2px 8px rgb(13 13 13 / 48%);
    width: 100% !important;
    height: 40px !important;
}
 

.avatar-uploader .ant-btn{
    background: #222325 !important;
    color: #FFF;
    border-radius: 20px;
    border:none !important;
    box-shadow: inset -4px -2px 8px #575757, inset 4px 2px 8px rgb(13 13 13 / 48%);
    width: 100% !important;
    height: 42px !important;
}

.ant-upload-list-item-name,
span.anticon-paper-clip
{
 color: #899AA4 !important;   
}

.anticon-delete{
    color:red !important
}

.ant-upload-list-item-info,
.ant-btn.ant-btn-text.ant-btn-sm.ant-btn-icon-only.ant-upload-list-item-card-actions-btn{
    background: transparent !important;
    background: transparent !important;
}


.ant-list ul.ant-list-items,
.ant-table
 {
    min-height: 300px !important;
    overflow-y: auto !important;
}

.pagination-table{
    position: relative;
    display: block;
}


.ant-list .ant-row:hover .ant-col .ant-btn-link span,
.ant-list  .ant-row:hover .ant-col a,
.ant-table  .ant-table-row:hover td a,
.blackTheme  .ant-row:hover .ant-col .ant-btn-link span,
.blackTheme  .ant-row:hover .ant-col .accion-text,
.blackTheme .ant-table tbody > tr:hover > td  .ant-btn-link span,
.list-item-access.blackTheme  li:hover  .item-access-row-column,
.list-item-access.blackTheme  li:hover  .item-access-row-column span.ant-typography,
.div-ultimosAccesos .div-notificacion:hover ,
.div-ultimosAccesos .div-notificacion:hover  span.ant-typography,
.div-ultimosAccesos .div-notificacion:hover  a
{
    color: #FFF !important;
    background:  #04FAA1 !important;
    color: #fff !important;
}


body {
  margin: 0;

  background-color: #131415;
    font-family: 'Rubik', sans-serif;
}


#root{
  background-color: #131415;
  height: 100% !important;
  margin: 0 auto;
}

.divLayout{
  height: 100% !important;
}

::-webkit-scrollbar
 {
    width: 8px;     /* TamaÃ±o del scroll en vertical */
    height: 8px;    /* TamaÃ±o del scroll en horizontal */
    background-color:#92a0b1;
    background-color:#000;

    border-radius: 5px;

}

/* Ponemos un color de fondo y redondeamos las esquinas del thumb */
::-webkit-scrollbar-thumb {
  background-color: #969595;
  background-color: #5b5e5e;
  background-color: #0f6160;
  border-radius: 5px;
}

/* Cambiamos el fondo y agregamos una sombra cuando estÃ© en hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #5b5e5e;
  background-color: #2a9f9e;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  padding:0.5px;
}

/* Cambiamos el fondo cuando estÃ© en active */
::-webkit-scrollbar-thumb:active {
  background-color: #5b5e5e;
  background-color: #2a9f9e;
}

::-webkit-scrollbar-track {
    background: #4e4e53;
    background: #090a0a;
    background:#131414;
    border-radius: 5px;
}


