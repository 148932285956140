body {
  margin: 0;

  background-color: #131415;
    font-family: 'Rubik', sans-serif;
}


#root{
  background-color: #131415;
  height: 100% !important;
  margin: 0 auto;
}

.divLayout{
  height: 100% !important;
}

::-webkit-scrollbar
 {
    width: 8px;     /* TamaÃ±o del scroll en vertical */
    height: 8px;    /* TamaÃ±o del scroll en horizontal */
    background-color:#92a0b1;
    background-color:#000;

    border-radius: 5px;

}

/* Ponemos un color de fondo y redondeamos las esquinas del thumb */
::-webkit-scrollbar-thumb {
  background-color: #969595;
  background-color: #5b5e5e;
  background-color: #0f6160;
  border-radius: 5px;
}

/* Cambiamos el fondo y agregamos una sombra cuando estÃ© en hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #5b5e5e;
  background-color: #2a9f9e;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  padding:0.5px;
}

/* Cambiamos el fondo cuando estÃ© en active */
::-webkit-scrollbar-thumb:active {
  background-color: #5b5e5e;
  background-color: #2a9f9e;
}

::-webkit-scrollbar-track {
    background: #4e4e53;
    background: #090a0a;
    background:#131414;
    border-radius: 5px;
}

