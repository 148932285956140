.content{
    background-color: #090A0A;
}


.page-title{
    font-weight: 400 !important;
    font-size: 26px !important;
    color: #9EA7AC !important;
    padding-left: 2rem; 
    margin: 0px !important;
}


.page-header-title [title]{
    color: #9EA7AC!important;
    font-weight: normal!important;
    font-size: 28px;
}

.card-access{
    height: 130px !important;
}

.card-access.blue {
    background: rgba(0, 195, 255, 0.2);
    border: 2px solid #00C3FF;
    border-radius: 10px;
}

.card-access.purple{
    background: rgba(160, 0, 254, 0.2)!important;
    border: 2px solid #A000FE!important;
    border-radius: 10px!important;
}

.card-access.green {
    background: rgba(35, 234, 164, 0.2);
    border: 2px solid #23EAA4;
    border-radius: 10px;
}

.card-access.yellow{
    background: rgba(254, 197, 0, 0.2);
    border: 2px solid #FEC500;
    border-radius: 10px;
}

.table-access-container{
    background: linear-gradient(94.6deg, #32383E -60.81%, #17191C 185.78%);
    border-radius: 10px;
    padding: 1em 1em;
    height: calc(100% - 26%);
    max-height: calc(100% - 26%);
    overflow-y: auto;
}

.title{
    color: #FFF8F8!important;
    font-size: 20px!important;;
    font-weight: normal!important;
}



.input-access{
    background: #494A4D;
    border-radius: 8px;
    border: 1px solid #494A4D !important;
    /*border-radius: 16px;*/
}

.input-access input {
    background: rgba(0,0,0,0.0);
    width: 100%;
    color: white;
    padding: 0.5em!important;

    margin-left: 7px;
}


.filters{
    text-align: end;
}

.list-item-access{
    width: 100%;
    min-height: calc(100vh / 2 + 90px);
    overflow-y: auto;
}
.list-header{
    color: #FFF !important; 
    font-weight: 700;
    font-size: 13pt;
    text-align: center !important;
    background: transparent!important;
    margin-bottom: 10px;
    transition: background 0.3s ease;
    padding: 0;
}
.list-header span{
    color: #fff;
}

.ant-list-item{
    border:none!important;
    padding-bottom: 0;
    /* padding-top: 0.3em; */
    padding:5px 0px 0px 0px!important;
}


.list-item-access .item-access-row {
    background: #171819;
    border-radius: 8px;
    width: calc(100% - 2em);
    border: none!important;
    position: relative;
    min-height: 52px;
    margin: 0px auto!Important;

}

.list-item-access .item-access-row .muesca{
    position: absolute;
    left: 1px;
    top: 6px;
    width: 4px;
    height: 78%;
    background: #04FAA1;
}


.list-item-access .item-access-row-column {
    color: #899AA4;
    display: flex;
    justify-content: center;
    align-items: center;
}


.list-item-access .item-access-row-column span{
    color: #899AA4;
}

.list-item-access .item-access-row:hover span,
.list-item-access .item-access-row-column span:hover{
    color: #899AA4;
}

.list-item-access .item-access-row-column:nth-child(6){
    width: 10% !important;
    text-align: end;
}

.ver-detalles{
    font-size: 12px;
    color: #FFF!important;
}


.table-requests-container{
    min-height: calc(100vh - 27vh);
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
}


.list-item-request{
    width: 100%;
}

.pagination-content .ant-pagination-item > * {
    background: #171819;
    border: none;
    color: white!important;
    font-family: 'Rubik', sans-serif;
}

.pagination-content .ant-pagination-item-link {
    background: #171819 !important;
    color: white!important;
}

.pagination-content{
    position: absolute;
    right: 0px;
    margin-bottom: 15px;
    bottom: -64px;
}


.modal-detAccesos{
    border-radius: 15px !important;
    background: linear-gradient(94.6deg, #32383E -60.81%, #17191C 185.78%) !important;
    box-shadow: -5px -6px 16px rgba(195, 200, 205, 0.04), 6px 6px 28px rgba(0, 0, 0, 0.3) !important;
    max-width: 750px !important;
    margin-top: 0px !important;
    top: 40px !important;
    max-height: none !important;
}

.modal-detAccesos .ant-modal-close{
    position: relative !important;
    float: left !important;
    margin-top: 5px;
    margin-bottom: -60px;
}

.modal-detAccesos .ant-modal-close-icon{
  display: none !important;
  visibility: hidden !important;
}

.modal-detAccesos .ant-modal-body{
    display: block !important;
    position: relative !important;
    float: left !important;
    width: 100% !important;
}

.modal-detAccesos .tituloModal{
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 20px;
    /* or 57% */
    letter-spacing: 0.1px;
    color: #04FAA1;
    margin: auto;
    min-height: 45px;
    text-align: center;
    padding: 12px 0px;
    margin-bottom: 15px;
}

.modal-detAccesos .row-info {
    overflow: auto !important;
    max-height: 755px;
}

.row-info .ant-row {
    width: 100%;
    position: relative;
    padding: 0px 20px;
}

.row-info .ant-col {
    margin-bottom: 28px !important;
}

.row-info .ant-col p,
.row-info .ant-col > span
{
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #899AA4 !important;
    margin-right: 5px;
    margin-bottom: 3px;
    margin-top: 15px !important;
}

.row-info .ant-col p

{   
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    color: #899AA4 !important;
}


.row-info .ant-col label{
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 20px;
}


.modal-detAccesos .svg-IconCopy,
.modal-detAccesos .svg-IconClip{
    margin-left: 5px;
    margin-bottom: -4px;
}



.row-cards-accesos{
    width: 100%;
}

.row-cards-accesos .ant-col{
    padding-right: 10px;
    padding-bottom: 10px;

}

.card-access {
    background: #282c31;
    border-color: #282c31 ;
    border-radius: 15px !important;
    width: 100% !important;
    padding: 1rem;
    min-width: 270px !important;
    border: none !important;
    overflow: hidden;
    background: transparent !important; 
}

.card-access .ant-card-body{
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: #282d32 !important;
}

.card-access .svg-IconAccess{
    margin-left: 2rem;
}

.card-access h1{
    font-size: 18px !important;
    color: #9ea7ac !important;
    font-weight: 500;
    text-align: center;
    margin-left: 10px;
    width: 70%;
}


.card-access .ant-card-body{
    padding:10px
}


/*Css para header de la tabla de Accesos*/
.row-enc-accesos .title{
    font-size: 22px !important;
    margin: 0;
}

.input-search 
{
    background: #494A4D !important;
    border-radius: 8px;
}

.input-search  .ant-input
{
    background: #494A4D;
    color: #9EA7AC;
}

.input-search  .ant-input-affix-wrapper
{
   background: transparent !important;
   border: none;
   padding: 0 0 0 10px;
}

.input-search .ant-input-group-addon{
    display: none;
}

.input-search  .ant-input::placeholder
{
    color: #9EA7AC !important;
}

 .filters-drop  .ant-select-arrow ,
.row-enc-accesos  .ant-input-suffix {
    display: none !important;
    visibility: hidden !important;
}

.row-enc-accesos .ant-picker-range{
    border-radius: 0px !important;
    height: 15px !important;
    min-height: 35px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;  
    box-shadow: none !important;
    background: transparent;
    border: solid 1px #33F898 !important;
}

.row-enc-accesos  .ant-picker input,
.row-enc-accesos  .ant-picker input::placeholder,
.row-enc-accesos .ant-picker .anticon-swap-right,
.row-enc-accesos .ant-picker .anticon-calendar{
    color: #33F898 !important;
}

.row-enc-accesos .ant-picker-range .ant-picker-clear{
    background: #24282c;
    right: 10px;
}

.row-enc-accesos .ant-picker-range .ant-picker-clear .anticon{
   color: #33F898;
}


 
.filters-drop .ant-select-selector{
   background: transparent;
}

.filters-drop .ant-select-selection-search,
 .row-enc-accesos .ant-select-selector{
    border-right: 0px;
    border: none !important;
}

.row-enc-accesos .ant-select-selection-item{

    padding: 0px !important;
}


.filters-drop .ant-select:not(.ant-select-disabled):hover .ant-select-selector{
    border-right-width: 0px !important;
}
.row-enc-accesos .ant-select-selection-item,
.row-enc-accesos .ant-select-selection-placeholder {
    color: #33F898 !important;
    opacity: 1 !important;
}




.svg-BtnPlus{
    background: red !important;
    /* Group 125 */
 

/* Ellipse 1 */
 

background: linear-gradient(126.63deg, #3FFCA0 11.98%, #00E676 83.35%);
box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.35), 0px 4px 4px rgba(0, 0, 0, 0.25);


/* Vector */
 
background: #FFFFFF;

}


.form-crearAcceso .ant-input-number,
.form-crearAcceso  input,
.form-crearAcceso .ant-picker   
 {
    color: #899aa4 !important;
    font-size: 12pt !important;
    font-weight: 300 !important;
    padding: 10px !important;
    border: solid 0px #171819 !important;
    min-height: 45px !important;
    width: 99% !important;
    max-height: 85px !important;
    box-shadow: inset -4px -2px 8px #575757, inset 4px 2px 8px rgba(13, 13, 13, 0.48) !important;
    border-radius: 20px!important;
    background: #222325 !important;
    border-radius: 50px !important;
}
 
.form-crearAcceso .ant-picker-input  

 {
    border-radius: 50px !important;
    background: #222325 !important;
    box-shadow: inset -4px -2px 8px #575757, inset 4px 2px 8px rgba(13, 13, 13, 0.48) !important;


}

.form-crearAcceso .ant-form-item{
    margin-bottom: 5px !important;
}



.form-crearAcceso .ant-col{
    margin-bottom: 10px !important;
    padding: 0px !important;
}


.form-crearAcceso .ant-form-item-control-input-content{
    height: auto !important;
    max-height: 200px !important;
    overflow: hidden;
}


.form-crearAcceso .ant-input-number ,
.form-crearAcceso .ant-picker.input-form{
    padding: 0px !important;
}

.form-crearAcceso .ant-picker.input-form{
    background: transparent !important;
    border: none;
}


.form-crearAcceso .ant-input-number input,
.form-crearAcceso .ant-picker-input input{
    box-shadow: none !important;
    background: transparent !important;
    border: none !important;

}


.form-crearAcceso .ant-picker-suffix
{
    margin-right: 10px !important;
}


.form-crearAcceso .ant-select-selector
{
    overflow: hidden !important;
}


.form-crearAcceso .dropzone-element{
    text-align: center;
    font-weight: bolder !;
}


.form-crearAcceso .ant-select-arrow{
    color:#12a875 !important
}


.form-crearAcceso  .ant-form-item-label > label.ant-form-item-required::before {
  display: none !important;
}

.row-info .form-crearAcceso  .ant-form-item-label {
    margin-left: 20px !important;
    font-family: Roboto !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 16px !important;
    line-height: 20px !important;
    margin-bottom: 10px !important;
    color: #899AA4 !important;
  }
  

.form-crearAcceso .item-input-clave,
.form-crearAcceso .item-input-clave.ant-form-item-has-error
{
    width: 70% !important;
    max-width: 450px !important;
    margin: 15px auto !important;
    padding: 0px !important;
    margin-left: 18% !important;
}


.form-crearAcceso .ant-form-item-control-input .ant-input-affix-wrapper.input-clave{
    padding: 4px 12px !important;
}

.form-crearAcceso .input-clave input{
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
}


.form-crearAcceso .btn-green{
    width: auto !important;
    height: auto !important;
    border: 1px solid transparent !important;
    background: linear-gradient(  88.33deg , #00E676 -7.64%, #00E676 145.94%) !important;
    box-shadow: -6px -6px 5px rgb(255 255 255 / 5%), 6px 8px 15px rgb(40 173 69 / 35%) !important;
    border-radius: 8px !important;
    font-weight: 500 !important;
    font-size: 18px !important;
    color: #fff !important;
    text-align: center !important;
    float: left !important;
    margin: 25px auto !important;
    min-width: 150px !important;
    margin-left: calc(50% - 80px ) !important;
}





.pagination-table .ant-select-selector {
    background: transparent !important;
}

.pagination-table .ant-select-selector .ant-select-selection-search{
    border-right: none !important;
}
.pagination-table  .ant-select-selector *
{
    color: #33f898;
} 
 