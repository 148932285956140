/*
    #414d55
    #131414
*/

.darkTheme{
    background: transparent !important;
}

.darkTheme .ant-picker-panel {
    background: #131414 !important;
}

.div-calendario
{
    padding: 10px;
    background: transparent !important;
    border-radius: 16px;
}

.ant-picker-calendar .ant-picker-panel .ant-picker-body{
    padding: 0px !important;
}

.darkTheme  .ant-select-arrow {
    color:#90a1ac;
 }

 .darkTheme .ant-radio-button-wrapper
 {
     background: transparent !important;
     color:#90a1ac;

 }

 .darkTheme   .ant-radio-group  
 {
     background: transparent !important;
 }

 .darkTheme  .ant-radio-group > label
 {
     background: #414d55 !important;
     color:#90a1ac;
     border:none ;
     border-radius: 5px;
     margin-left: 10px;
 }

 .darkTheme  .ant-radio-button-wrapper:not(:first-child)::before {
     background: transparent !important;
 
 }

 .darkTheme  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child,
 .darkTheme  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
     z-index: 1;
     color: #2a9f9e !important;
     background: #fff;
     box-shadow: -1px 0 0 0 #2a9f9e !important;
 }

 .darkTheme  .ant-picker-content  thead {
     background: #090a0a;
     text-align: center;
 }
 
 .darkTheme  .ant-picker-content  th {
     background: #090a0a;
     color:#9ea7ac;
     padding: 15px !important;
     text-align: center !important;
     border:solid 1px #232425;
     font-size: 15pt;
     
 }

 

 .darkTheme  .ant-picker-content thead th:first-child{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.darkTheme  .ant-picker-content thead th:last-child{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.darkTheme  .ant-picker-content td{
    border:solid 1px #232425; 
}

.darkTheme  .ant-picker-content .ant-picker-calendar-date, 
.darkTheme  .ant-picker-content .ant-fullcalendar-date {
    border-top: none;
}

.darkTheme  .ant-picker-content  .ant-picker-calendar-date-value{
    color:#9ea7ac !important;
    font-size: 18px;
    text-align: left !important;

}
 
.darkTheme .ant-badge-status-text,
.darkTheme  .notes-month
{
     color:rgb(233, 233, 233);
 }

 .darkTheme  .ant-picker-content .ant-picker-calendar-date:hover, 
.darkTheme  .ant-picker-content .ant-fullcalendar-date {
    background: #2a9f9e !important;
    background: #589b9a !important;
    background: #0f3837 !important;
}

 
.darkTheme .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date, 
.darkTheme .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date,
.darkTheme .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today, 
.darkTheme .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today 
{
    background: #1f2325 !important;
    
}
.ant-picker-calendar-header {
    display: block;
}

.ant-picker-calendar-header .ant-picker-calendar-year-select,
.ant-picker-calendar-header .ant-picker-calendar-month-select
{
    min-width: 180px !important;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
    height: 65px;
    display: inherit !important;
}

.contenedor-etiquetas .divNivel{
    min-width: 100px !important; 
}

.radioCalFiltro{
    background: transparent !important;
}

.radioCalFiltro .ant-radio-button-wrapper ,
.radioCalFiltro .ant-radio-button-wrapper:not(:first-child)::before 
{
    border-left: 1px solid #555 !important;
    background-color: transparent;
}

.radioCalFiltro label{
    background: transparent !important;
    color:#AAA;
    border-color: #555;
    font-weight: 400;
    font-size: 12pt;
    margin: 3px 5px;
}


.radioCalFiltro .ant-radio-button-wrapper:hover {
    
    position: relative;
    color: #589b9a;
}

.radioCalFiltro .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before,
.radioCalFiltro .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #2a9f9e !important;
    border-color:  #2a9f9e !important;
    background: #2a9f9e;
}

.radioCalFiltro label{
    background: transparent !important;
    color:#AAA;
    border-color: #555;
    font-weight: 400;
    font-size: 12pt;
    margin: 5px 5px;
}


.lvlTodos svg {color: teal;}
.lvlDenegados svg {color: #fa8c16;}
.lvlCompletados svg {color: #52c41a;}
.lvlPendientes svg {color: #1890ff;}
.lvlError svg {color: #ff4d4f;}


.lvlAprobada svg {color: #1890ff;  margin-left: 5px;}
.lvlSolicitada svg {color: #FDCA40;  margin-left: 5px;}
.lvlDenegada svg {color:#d60420;  margin-left: 5px;}
.lvlTodas svg {color: #00E676; margin-left: 5px;}


 
.modal-detCalendario{
    background: linear-gradient(94.6deg, #32383E -60.81%, #17191C 185.78%);
    padding-bottom: 0px;
}

.modal-detCalendario .ant-modal-body {
    padding: 0px;
}
.modal-detCalendario .ant-modal-content {
    height: 100%;
    background:transparent!important;
    overflow: auto;
}

.modal-detCalendario .anticon-info-circle {
    display: none;
    visibility: hidden;
}


.modal-detCalendario .ant-modal-confirm-title {
    background-color: #171819;
    color: #FFF;
}

.modal-detCalendario .ant-modal-confirm-title h2 {
    color: #CCC;
    margin-left: 10px;
    padding: 10px 0px 0px 10px;
    font-size: 15pt;
}

.modal-detCalendario .ant-modal-close {
    display: block !important;
    color: #cccccc;
}



.modal-detCalendario .ant-modal-confirm-content .ant-row {
    max-height: 70vh;
    overflow: auto;
}

.modal-detCalendario label {
    color: #DDD !important;
    font-size: 13pt;
}

.modal-detCalendario p {
    color: #999 !important;
    font-size: 11pt;
}

.modal-detCalendario .ant-modal-confirm-btns {
    padding: 0px 15px 15px 0px;
}


.modal-detCalendario .ant-btn-primary {
    background-color: teal;
    border-color: teal;
}


/*Otros*/
.calendario-layout .dashboard-section-header,
.calendario-layout .divForm
{
    background: transparent !important;
    box-shadow: none !important;
}

.calendario-layout .contenedor-etiquetas .divNivel {
 border: none !important;
}

.calendario-layout .radioCalFiltro .ant-radio-button-wrapper:not(:first-child)::before {
    border-left: none!important;
    
}

.span-calendar{
    background: #1890ff;
    padding: 2px 10px;
    border-radius: 10px;
    color: white;
}

.calendario-admisiones .ant-picker-calendar-date-content{
    display: flex;
    justify-content: center;
    align-items: center;
}

.calendario-admisiones .ant-radio-group{
    display: none;
}

.calendario-admisiones .ant-select-arrow{
    left: 90% !important;
}