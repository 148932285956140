.Dashboard {
  height: 99% !important;
}


/* input auto fill input style */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
{
    -webkit-box-shadow: 0 0 0 50px #07a3a1 inset !important;
    padding-left: 5px!important;
    border-radius: 20px!important;
}