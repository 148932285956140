/*.sidebar .ant-layout-sider-children{
    border: none;
    background: #090a0a;
    border-image: linear-gradient(to bottom, #28999d 5%, #090a0a 100%) 1;
    border-top: 1px;
    border-left: none;
    border-bottom: none;
}

.sidebar .ant-menu-inline {
    color: #fff;
}

.sidebar .ant-menu{
    background: linear-gradient(94.6deg, #32383E -60.81%, #17191C 185.78%)!important;
    box-shadow: -5px -6px 16px rgba(195, 200, 205, 0.04), 6px 6px 28px rgba(0, 0, 0, 0.3);
    border-radius: 16px;
    color: #fff;
    border-right-color: transparent;
    height: 100vh;

}
.ant-menu-submenu-popup,
.ant-menu-submenu-popup > .ant-menu{
    background: rgba(23,24,25,0.5) !important; 
    border-radius: 16px;
    color: #fff;
}
.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left{
    border-right-color: none;
}
.sidebar .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.sidebar .ant-menu-item:hover, 
.sidebar .ant-menu-item-active,
.sidebar .ant-menu-item:hover i:after,
.sidebar .ant-menu-item-active i:after,
.sidebar .ant-menu-item:hover i:before, 
.sidebar .ant-menu-item-active i:before,
.sidebar .ant-menu:not(.sidebar .ant-menu-inline) .sidebar .ant-menu-submenu-open,
.sidebar .ant-menu-submenu-active,
.sidebar .ant-menu-submenu-title:hover
{
    background: transparent;
    color: #00E676;
}


.sidebar .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after, 
.sidebar .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after, 
.sidebar .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after, 
.sidebar .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after, 
.sidebar .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before, 
.sidebar .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before, 
.sidebar .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before, 
.sidebar .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before{
    background: #fff;
}

.sidebar .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, 
.sidebar .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, 
.sidebar .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, 
.sidebar .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after, 
.sidebar .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, 
.sidebar .ant-menu-submenu-vertical-left > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, 
.sidebar .ant-menu-submenu-vertical-right > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, 
.sidebar .ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before{
    background:#00E676 ;
}

.sidebar .ant-menu-vertical .ant-menu-item::after, 
.sidebar .ant-menu-vertical-left .ant-menu-item::after, 
.sidebar .ant-menu-vertical-right .ant-menu-item::after, 
.sidebar .ant-menu-inline .ant-menu-item::after{
    border-right:3px solid #00E676;
}
.ant-menu.ant-menu-light .ant-menu-item:hover {
    background: transparent;
    color: #00E676;
}*/

/*.ant-menu-item-group {
    height: 32px;
    font-weight: 500;
}

.ant-menu-submenu ul {
    background: #1f2121 !important;
}

.ant-menu-inline-collapsed > .ant-menu-submenu {
    left: 0;
    padding: 0 0px !important;
    text-overflow: clip;
}

.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title > span > .anticon {
    margin-right: 30px;
}


.ant-menu-submenu.ant-menu-submenu-popup.ant-menu-dark.ant-menu-submenu-placement-leftTop { 
    max-width: 250px !important;
}*/

/*.sidebar .ant-menu-submenu ul{
    margin-left: 0;
    background: #171819!important;
    border-radius: 16px;
    box-shadow: none;
    height: auto;
}*/

/*----------------------------------------------------------------------*/

.sidebar{
    background: transparent !important;
    margin-bottom: 1rem;
    min-height: 100%;
    padding: 20px 5px!important;
    
}

.sidebar .nav-bar{
    height: 100%;
    max-height: 1000px;
    border-radius: 16px;
    background: linear-gradient(94.6deg, #32383E -60.81%, #17191C 185.78%);
    border-right: none;
    color: #fff;
    position: relative;
    
    
    
    min-height: 100%;
    background: #2C2F33 !important;
    box-shadow: -6.22302px -6.22302px 18.6691px #3b4451c2,
     6.22302px 6.22302px 18.6691px #000000 !important;
    border-radius: 24.8921px !important;

  

}

.sidebar .nav-bar .group-bottom{
    position: absolute;
    bottom: 0;
    width: 100%;
}

.sidebar .nav-bar svg{
   fill: #fff;
   width: 25px;
   height: 25px;
   min-width: 25px;
}

.sidebar .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background: transparent;
    color: #00E676;
}

.sidebar .ant-menu-item-active{
    color: #00E676 !important;
}

.sidebar .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected svg{
    fill: #00E676;
}

.sidebar .ant-menu-inline .ant-menu-item::after{
    border-right: 3px solid #00E676;
}

.sidebar .ant-menu-item:active{
    background: transparent;
}

.sidebar .nav-item{
    display: flex;
    align-items: center;
}

.sidebar .nav-item .nav-text{
    margin-left: 12px;
    overflow: hidden;
}