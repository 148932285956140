@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');
.Login{
  display:flex;
  justify-content: center;
  min-height: 100vh;
  background:#161616;
}
.Login-Title{
  color: #899AA4;
  font-family: Roboto,sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
}
/* form container */
.login-form{
  position: relative;
  width: 350px;
  padding: 20px 20px ;
  text-align: center;
  background: linear-gradient(94.6deg, #32383E -60.81%, #17191C 185.78%);
  box-shadow: -5px -6px 16px rgba(195, 200, 205, 0.04), 6px 6px 28px rgba(0, 0, 0, 0.3);
  border-radius: 16px;
}


/* input label */
.login-form
.ant-form-item-label
{
    margin-bottom: 5px;
    vertical-align: center;
    text-align: center;
    float: initial;
    width: 100%;
    color: #899AA4;
    font-family: Roboto,sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;

}




.login-form .ant-form-item-control input:hover,
.login-form .ant-form-item-control textarea:hover{
  border: none!important;
}
.login-form input{
  border: none !important;
  text-align: left;
}
 
 .login-form
 .ant-input-affix-wrapper .ant-input-prefix {
   font-size: 20px;
    color: #00E676;
}

 .login-form .ant-form-item-control input,
 .login-form .ant-form-item-control textarea
 {
    color:#899aa4;
    border-radius: 12px;
    background: #171819;
    border: none !important;
    font-size: 10pt !important;
    font-weight: 400;
   
}
form input, .ant-input-affix-wrapper, .ant-select-selection, .ant-select-selector,
input:-internal-autofill-selected{
  background: transparent !important;
}

.login-form .ant-input-affix-wrapper{
  background-color: transparent;
}


/* button submit */
.login-submit-btn{
  width: 174px!important;
  height: 43px!important;
  border:1px solid transparent!important;
  background: linear-gradient(88.33deg, #00E676 -7.64%, #00E676 145.94%)!important;
  box-shadow: -6px -6px 16px rgba(255, 255, 255, 0.1), 6px 9px 40px rgba(40, 173, 69, 0.54)!important;
  border-radius: 8px!important;
  font-weight: 700!important;
  font-size: 18px!important;

}
.login-submit-btn:hover,
.login-submit-btn:focus,
.login-submit-btn:active{
  border: 1px solid transparent!important;
  background: linear-gradient(88.33deg, #02b65f -7.64%, #00E676 145.94%)!important;
}

.link{
  color: #899AA4;
  padding: 10px 0px;
}
@media all and (min-width: 480px) {
    .Login {
      padding: 60px 0;
    }
  
    .Login form {
      margin: 0 auto;
      max-width: 400px;
    }
  }