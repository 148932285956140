/*Contenido*/
main {
    width: 100%;
    color: #FFF;
    background-color: #2C2F33;
}

.ant-layout{
    height: 100%;
}

.divLayout > .ant-layout-has-sider {
    background: #2C2F33 !important;
    min-height: 90vh !important;
    /* padding:25px !important; */
}

.divContenedorLayout{
    margin-left: 1rem;
    margin-right: 1rem;
}

/* layouts */
.dashboard-section-layout
{
    background:transparent !important ;
    border-radius: 16px;
    margin: 0px 1rem;
    overflow: hidden;
    margin-bottom: 1rem;
    
}

.dashboard-section-layout{
    padding: 25px 15px!important;
    margin: 0px !important;
}

.dashboard-section-page-header{
    background: #2a2b2d !important;
    
}
.dashboard-section-header{
    background: linear-gradient(94.6deg, #32383E -60.81%, #17191C 185.78%);
    border: none;
    padding: 5px 25px;
  
}
.dashboard-section-header > .ant-row{
    min-height: auto;
    
}
.dashboard-section-header-accesos{
    background: #2C2F33!important;
}
.dashboard-section-content{
    min-height: 100%;
    margin-top: -1px;
}

.nombreVista .ant-page-header-heading-left .ant-page-header-heading-title,
.nombreVista{
    text-align: left !important;
    font-family: Rubik !important;
    font-style: normal !important;
    font-weight: 500 !important;
    color: #9EA7AC !important;
    font-size: 28px;
    line-height: 40px;
    color: #9EA7AC;
    margin-bottom: 0px;
   } 

/*Menu*/
.trigger {
    float: left;
    margin: 20px;
    margin-left: 28px;;
    font-size: 25px;
    color: #FFF;
}

#components-layout-demo-custom-trigger.trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
}

#components-layout-demo-custom-trigger.logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
}


.border-bottom-none{
    border-radius: 0px!important;
}

/*Contenedores*/
.div-contenedor {
    padding: 10px !important;
    text-align: center;
    background: linear-gradient(94.6deg, #32383E -60.81%, #17191C 185.78%);
    box-shadow: -5px -6px 16px rgba(195, 200, 205, 0.04), 6px 6px 28px rgba(0, 0, 0, 0.3);
    border-radius: 16px;
    margin-bottom: 1rem;

 

background: linear-gradient(140.63deg, #2C2F33 0%, #414D55 153.32%) !important;
box-shadow: 14px 14px 40px rgba(16, 16, 18, 0.75), -7px -7px 30px #262E32 !important;
border-radius: 8px !important;



}

.div-ultimosAccesos{
    height: calc(100vh / 2);
    max-height: calc(100vh /2)!important;
    overflow-y: auto;
}
.container-notificaciones{
    height: 100vh;
    min-height: 100vh;
    max-height: 100vh;

}

.div-notificaciones{
    overflow-y: auto!important;
    width: 100%;
    min-height: calc(100% - 80px)!important;
    height: calc(100% - 80px)!important;
    max-height: calc(100% - 80px)!important;
}
.container-notificaciones> .div_titulo {
    height: auto !important;
    min-height: 80px;
}


.item-request-row{
    position: relative;
    padding: 0.5rem 1rem;
    background: #171819;
    border-radius: 8px;
    width: 100%;

}
.button-notificacion{
    background: #FAE104;
    color: #fff;
    top: -10px;
}
.button-notificacion:hover,
.button-notificacion:focus,
.button-notificacion:active{
    background:#FAE104;
    opacity: 0.8;
    color: #fff;
}
 .muesca{
    position: absolute;
    left: 1px;
    top: 6px;
    width: 4px;
    height: 85%;
    background: #FAE104;
}

 .request-title{
    font-size: 15px!important;
    color: #90A1AC!important;
}

 .request-description{
    font-size: 12px;
    color: #90A1AC;
    max-width: 80%;
}

.request-date {
    color: white;
    font-size: 12px;
    position: absolute;
    right: 8px;
    z-index: 112312;
    bottom: -1px;
}


.div-notificacion{
    background-color: #171819 !important;
    border-radius: 10px;
    border:none !important;
    box-shadow: none !important;
    min-height: 45px !important;
    height: auto;
    padding: 10px !important;
    padding-left: 5px !important;
    width: 99% !important;
    margin: 0px 0px 5px 0px !important;
}

.border-solicitud{
    position: relative;
    width: 3px;
    top: 18%;
    height: 40px;
    background: #FAE104 !important;
}
.border-notificacion{
    width: 3px;
    height: 40px;
    background:  #08e093 !important; 
}
.div-solAcceso {
    width: 100%;
    padding: 5px;
    height: 80px;
    height: auto;
    margin-bottom: 5px;
}

.div-solAcceso label {
    float: right;
    width: 98%;
    text-align: left;
}


.IconSquare {
    font-size: 42px;
    color: #2C2F33;
    border-radius: 10px;
    float: left;
}

/*Notificaciones*/
.div-nivelesNotificacion {
    float: left;
    width: 100%;
    height: auto !important;
    padding: 1px;
    background-color: #2C2F33 !important;
    border-radius: 10px;
    font-size: 12.5px;
    margin-top: 3px;
}

.divNivel {
    width: auto;
    float: left;
    padding: 5px;
    min-width: 25%;
    opacity: 1 !important;
    z-index: 100;
}

.divNivel > svg {
    font-weight: bold !important;
    color: transparent;
    font-size: 12px;
    border-radius: 100%;
    padding: 0px;
    border: solid 3px red;
    margin-right: 5px;

}


.nivel-a > .ant-btn,
.nivel-b > .ant-btn,
.nivel-c > .ant-btn,
.nivel-d > .ant-btn,
.nivel-e > .ant-btn {
    background: transparent;
    color: #FFF;
    border-color: #666;
}


.nivel-a > .ant-btn > svg {
    color: #34d1bf !important;
    margin-right: 5px;
    margin-top: 3px;
}

.nivel-b > .ant-btn > svg {
    color: #2662f0 !important;
    margin-right: 5px;
    margin-top: 3px;
}

.nivel-c > .ant-btn > svg {
    color: #0496ff !important;
    margin-right: 5px;
    margin-top: 3px;
}

.nivel-d > .ant-btn > svg {
    color: #ed0423 !important;
    margin-right: 5px;
    margin-top: 3px;
}

.nivel-e > .ant-btn > svg {
    color: #fdca40 !important;
    margin-right: 5px;
    margin-top: 3px;
}

.nivel-a > svg, .nivel-a > .ant-btn > svg {
    border-color: #34d1bf !important;
}

.nivel-b > svg, .nivel-b > .ant-btn > svg {
    border-color: #2662f0 !important;
}

.nivel-c > svg, .nivel-c > .ant-btn > svg {
    border-color: #0496ff !important;
}

.nivel-d > svg, .nivel-d > .ant-btn > svg {
    border-color: #ed0423 !important;
}

.nivel-e > svg, .nivel-e > .ant-btn > svg {
    border-color: #fdca40 !important;
}

/*Card*/
.ant-card {
    border-radius: 5px;
    padding: 10px;
    color: gray;
}

.ant-card-cover img {
    border-radius: 5px;
    max-height: 165px;
}

.ant-card-body {
    padding: 5px;
    zoom: 0;
}


/*Footer*/
.divFooter {
    background-color: #161718 !important;
    border-top: solid 1px #2C2F33;
    color: white !important;
    text-align: center;
    height: 30px;
    padding: 5px;
    font-size: 13px;
}


.div_titulo {
    top: 0;
    position: relative;
    min-height: 28px;
    font-size: 16px;
    text-align: left;
    color: #dee0e3;
    font-weight: 500;
    padding: 3px;
    height: auto;
    padding-bottom: 0px;
}

.div_contenido {
    min-height: calc(100% - 28px) ;
    height: calc(100% - 28px) !important;
    max-height: calc(100% - 28px);
    overflow: auto;
   
}


/*Calis*/
.div-principalContent {
    width: 99% !important;
    border-radius: 30px !important;
    float: left;
    position: relative;
    overflow: auto;
    height: 95%;
}


.nombre-solicitud
{
    color: #fff;
    float: left;
    margin-left: 15px;
}
.descripcion-solicitud{
    color: #fff;
    margin-left: -20px;
}
.fecha-notificacion{
    color: #9EA7AC;
    font-size: 10px;

}
.lblTitulo {
    font-weight: bold;
}

.container-notificaciones > .div_contenido {
    padding-right: 10px;
}
.list-full{
    max-height: calc(100vh - 140px);
    overflow-y: auto;
    height: calc(100vh - 140px);
}

.div-notificacion-item {
    background: transparent!important;
    border: none;
    border-radius: none !important;
    box-shadow: none;
    border-bottom: dashed 1px gray !important;
    width: 98% !important;
    margin: 0px !important;
    height: 100px;
    padding-top: 10px;
}
.notificacion-box{
    background: rgba(65, 77, 85, 0.8);
    height: 30px;
    width: 30px;
    border-radius: 5px;
}

.notificacion-nivel{
    font-size: 24px;
    color: #1C8DF7;
    background: rgba(65, 77, 85, 0.5);
    display: block;
    margin: auto;
    padding-top: 5px;
}
.notificacion-nombre{
    display: block;
    color: #9EA7AC;
    text-align: left;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;

}
.notificacion-fecha{
    display: block;
    text-align: left;
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 20px;
    /* identical to box height, or 200% */
    letter-spacing: 0.416667px;
    text-transform: uppercase;
    color: #9EA7AC;

}
.divForm {
    height: 100%;
    text-align: left !important;
    padding: 10px;
    background: linear-gradient(94.6deg, #32383E -60.81%, #17191C 185.78%);
    box-shadow: -5px -6px 16px rgba(195, 200, 205, 0.04), 6px 6px 28px rgba(0, 0, 0, 0.3);
}

.divForm form{
    padding: 0px 1rem;
}

/*BOTONES*/
.btn, .btn-negro {
    float: right !important;
    text-align: center !important;
    border: none !important;
    min-width: 140px !important;
    margin-left: 10px;
    margin-bottom: 5px;
    border-radius: 5px;
    /* box-shadow:  0 1px 8px #ffffff80; */
}

.btn-azul {
    background: #2662f0 !important;
}

.btn-rojo {
    background: hsl(356, 73%, 45%) !important;
}

.btn-morado {
    background: #6c6ff0 !important;
}

.btn-tema {
    background: #2a9f9e !important;
}

.btn-negro {
    background: #2a2b2d !important;
    color: #FFF;
}

.btn-azul:hover {
    background: #4b79e4 !important;
}

.btn-rojo:hover {
    background: #c24b53 !important;
}

.btn-morado:hover {
    background: #8b8df5 !important;
}

.btn-tema:hover {
    background: #569c9b !important;
}

.btn-negro:hover {
    background: #569c9b !important;
}


.divBotones {
    height: auto !important;
    min-height: 10px !important;
}

.nView {
    visibility: hidden !important;
}

.div-Row {
    float: left;
    height: auto;
    width: 100%;
}


/*INPUTS*/
.ant-form-item-with-help {
    float: left;
    width: 100% !important;
}

.ant-form-item-with-help > .ant-form-item-control-wrapper {
    min-height: 65px;
    text-align: left;
}

.ant-form-item-control-input .ant-input-affix-wrapper,
.ant-form-item-control-input-content textarea,
.ant-input-search .ant-input,
.ant-picker {
    color: #899aa4;
    font-size: 12pt !important;
    font-weight: 300;
    padding: 10px !important;
    border: solid 0px #171819;
    min-height: 45px;
    width: 99%;
    max-height: 85px;
    background: linear-gradient(94.6deg, #32383E -60.81%, #17191C 185.78%);
    box-shadow: inset -4px -2px 8px #575757, inset 4px 2px 8px rgba(13, 13, 13, 0.48);
    border-radius: 20px!important;
    background: #222325 !important;
    height: 35px;
}

.input-filtro .ant-input-group-addon{
    background: transparent;
    padding-left: 1.5rem !important;
}

.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-select {
    border: solid 1px #8a3441 !important;
}


form input,
.ant-input-affix-wrapper,
.ant-select-selection,
.ant-select-selector {
    border-radius: 5px;
    border: 0px !important;
    text-align: left;
    color: rgba(255, 255, 255, 0.897) !important;
    font-size: 12pt;
    font-weight: 400 !important;
}

input::placeholder {
    color: #565b5e !important;
    font-weight: 400;
    font-size: 11pt;
}


.ant-select-selection-placeholder {
    color: #919ba1 !important;
    padding-top:6px!important;
}

.ant-picker-input > input {
    color: #899aa4;
}

tr:hover input,
.ant-picker-input:hover > input {
    color: #FFF;
}

.ant-select-selector,
.ant-select-selector:hover,
.ant-form-item-has-error .ant-select .ant-select-selector,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector
.ant-select .input-form .ant-select-focused .ant-select-single .ant-select-allow-clear .ant-select-show-arrow .ant-select-open,
.ant-form-item-has-error .ant-select .ant-select-open .ant-select-selector,
.ant-form-item-has-error .ant-select .ant-select-focused .ant-select-selector {
    border-color: #ffffff00 !important;
    border: solid 0px !important;
    box-shadow: none !important;
}

.anticon-calendar,
.ant-select-arrow {
    color: #899aa4 !important;
    margin-right: 0px !important;
    cursor: pointer;
}

/*.ant-form-item-control-input:hover .ant-select .ant-select-selector,*/
.ant-select .input-form .ant-select-single .ant-select-allow-clear a.nt-select-show-arrow .ant-select-open:hover,
.ant-select .input-form .ant-select-single .ant-select-allow-clear .ant-select-show-arrow:hover,
.ant-form-item-control-input:hover .ant-select-focused .ant-select-selector,
.ant-form-item-control-input .ant-select-focused .ant-select-selector,
.ant-select .input-form .ant-select-single .ant-select-allow-clear .ant-select-show-arrow {
    border: 0px solid transparent !important;
}
.ant-input:focus, .ant-input-focused{
    outline: 0;
    border-color: none!important;
     -webkit-box-shadow:none!important; 
     box-shadow: inset -4px -2px 8px #575757, inset 4px 2px 8px rgba(13, 13, 13, 0.48);
}
.ant-select-clear {
    display: inline-block;
    margin-top: -6px;
    color: #899aa4;
    color: #8a3441;
    font-size: 12pt;
    font-style: normal;
    padding-top: 0px;
    background: transparent !important;
    margin-right: 20px;
}



.ant-select-clear:hover {
    color: #c8464f;
}

.ant-switch {
    border-radius: 16px !important;
    height: 34px;
    width: 58px;
    margin-top: 6px;
    /* background: #171819; */
    border:  1px solid  #32383E;
    box-sizing: border-box;
    background: rgba(19, 20, 22, 0.2);
        box-shadow: inset 2px 2px 8px rgba(4, 4, 5, 0.6);
    /* box-shadow: inset -4px -2px 8px #575757, inset 4px 2px 8px rgba(13, 13, 13, 0.48)!important; */
}

.ant-switch-checked {
     background-color: #171819;
    box-shadow: inset 2px 2px 8px rgb(4 4 5 / 60%);
}

.ant-switch::after {
    background: #8ed1a7;
    border-radius: 3px !important;

}

.ant-switch-handle{
    top: 6px;
    
}
.ant-switch-checked .ant-switch-handle{
    left: calc(100% - 18px - 5px);
}

.ant-switch-checked::after {
    background: #0fd65a !important;
    border-radius: 3px !important;
}

.ant-switch-handle::before {
    background-color: #8ed1a7;
    border-radius: 25px;
}

.ant-switch-checked .ant-switch-handle::before {
    background-color: #0fd65a;
    background: linear-gradient(135deg, #16FF73 0%, #0FD75A 49.33%, #09AD41 100%);
    border: 2px solid rgba(255, 255, 255, 0.3);
    box-sizing: border-box;
}

.ant-form-item-explain {
    text-align: left;
    margin-bottom: 10px;
}

form .ant-form-item-label {
    float: left !important;
    text-align: left;

}

form .ant-form-item-label label {
    color: #899aa4;
    font-size: 11pt;
}

.ant-form-item > .ant-form-item-label {
    width: 100%;
}

.ant-select-dropdown {
    background: #161616 !important;
    background: #161718 !important;
    border: solid 0.5px #2a4b4a;
    width: auto;
    max-width: 500px;
    border-radius: 5px;
    padding: 5px;
    min-width: 100px !important;

}

.ant-select-dropdown
.ant-select-item-option {
    background: #161616 !important;
    background: rgba(68, 67, 67, 0.116) !important;
    border-left: solid 2px #589b9a;
    color: #899aa4;
    border-radius: 5px;
    margin-top: 5PX;
    margin-right: 5px;
}

.ant-select-dropdown
.ant-select-item-option-active {
    background: #161616 !important;
    background: #589b9a !important;
    background: #2a9f9e !important;
    border-bottom: solid 1.5px #18a19f27;
    border-bottom: solid 1.5px #07a3a1;
    border-left: solid 1px #161616;
    color: #FFF;
    border-radius: 5px;
    margin-top: 5PX;

}


/*Tablas*/
.div-scroll {
    overflow: auto !important;
}

.div-scroll > .div-contenedor {
    width: 97%;
    float: left;
}

.blackTheme {
    width: 100%;
}

.blackTheme .ant-table-content {
    background: rgba(255, 255, 255, 0) !important;
    background: #2a2b2d !important;
}
.blackTheme thead{
    background: linear-gradient(94.6deg, #32383E -60.81%, #17191C 185.78%)!important;
}
.blackTheme th {
    color: #FFF !important;
    font-weight: 700;
    font-size: 13pt;
    text-align: center !important;
    background: transparent!important;
    border-bottom: solid 2px #FFF;
    margin-bottom: 10px;
    -webkit-transition: background 0.3s ease;
    transition: background 0.3s ease;
}

.blackTheme .ant-table-tbody {
    overflow: auto !important;
}

.blackTheme .ant-table-tbody > tr > td {
    color: #899aa4;
    font-size: 11pt;
    text-align: center;
    border: none !important;
    background: #171819;
    height: 60px;
    min-height: 60px;
    max-height: auto;
    border-bottom: solid 5px #2a2b2d !important;
    padding: 3px;
}



.ant-list .ant-row:hover .ant-col ,
.ant-list  .ant-row:hover .ant-col ,
.ant-table  .ant-table-row:hover td ,
.blackTheme  .ant-row:hover .ant-col ,
.blackTheme  .ant-row:hover .ant-col ,
.blackTheme .ant-table tbody > tr:hover > td 
{
    color: #b8cfdd !important;
}


.blackTheme .ant-table tbody > tr.ant-table-row:hover > td ,
.blackTheme .ant-table tbody > tr.ant-table-row:hover > td .tag-permisos,
.blackTheme .ant-table tbody > tr.ant-table-row:hover >  td >  a.acciont-text,
.blackTheme .ant-table tbody > tr.ant-table-row:hover > td >  a.acciont-text span,
.ant-list .ant-row:hover ,
.ant-table  .ant-table-row:hover ,
.blackTheme .ant-table tbody > tr:hover
{
    background: #589b9a !important;
    color: #DDD !important;
    background:  #04FAA1 !important;
    color: #fff !important;
}

.blackTheme td > a {
    font-size: 9pt !important;
    vertical-align: center;
    color: rgb(37, 138, 253);
}

.blackTheme tbody td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.blackTheme tbody td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.blackTheme .ant-table-tbody > tr:first-child td {
    border-top: solid 5px #2a2b2d !important;
}

.spnAcciones a,
.spnAccionesUsu a {
    margin: 5px;
    background: #171819;
    border-radius: 5px;
    border: solid 1px #171819;
    padding: 2px;
    width: 34px !important;
    height: 34px !important;
    float: initial;
    display: inline-flex;
    box-shadow: inset -2.5px -2.5px 1px #3e3f3f, inset 1px 1px 0.5px #171819;

}

.spnAcciones a svg,
.spnAccionesUsu a svg {
    width: 25px !important;
    height: 25px !important;
    padding: 3px;
    color: #FFF;
    border-radius: 3px;
}

.EditIcon svg,
.EditIconUsu svg {
    background: #0084ff;
    border: solid 1px #4ca9ff;
}

.DeleteIcon svg,
.DeleteIconUsu svg {
    background: red;
    border: solid 1px #ff4c4c;
}

.icon-disabled ,
.btn-disabled,
.btn-disabled:hover{
    background: #8d8d8d71 !important;
    display: block;
    cursor: not-allowed !important;
}

.btn-disabled .icon-disabled {
    background: #fd5858 !important;
    border: solid 1px #ff4c4c !important;
    cursor: not-allowed !important;
}

.icon-disabled,
.icon-disabled:hover,
.icon-disabled:focus,
.icon-disabled:active
{
    cursor: not-allowed !important;
}

.ant-input-group-addon {
    padding: 0px;
    background: transparent;
}

.ant-input-search-button,
.ant-input-search-button:hover {
    /* display: none; */
    background-color: #2a9f9e !important;
    border-color: #2a9f9e !important;
    height: 45px !important;
    margin-left: -5px !important;
    border-radius:  20px 20px 20px 20px !important;
    border:none !important;
}

.ant-empty-description {
    color: #FFF;
}

.ant-table-cell:hover .ant-empty-description {
    color: gray
}


/* paginador */
.pagination-table {
    float: right!important;
    padding-top: 10px;
}
.pagination-table .ant-pagination-item > * {
    background: #171819;
    border: none;
    color: white!important;
    font-family: 'Rubik', sans-serif;
}
.pagination-table .ant-pagination-item-active *,
.pagination-table .ant-pagination-item:focus *,
.pagination-table .ant-pagination-item:hover *{
    border-color: #0f6160;
    background: #0f6160;
}

.pagination-table .ant-pagination-item-link {
    background: #2a9f9e !important;
    color: white!important;
    border-color: #2a9f9e;
}

.pagination-table li .ant-pagination-item-ellipsis{
    color: #fff;
}

.modalConfirm {
    z-index: 2000 !important;
    background: linear-gradient(94.6deg, #32383E -60.81%, #17191C 185.78%);
    opacity: 1;
    padding-bottom: 0;
}

.spanStatus {
    border-radius: 10px;
    padding: 0px 10px;
    color: #eee;
    width: auto !important;
    min-width: 65px;
    display: block;
}

.statusActivo {
    background-color: #2bd073;
}

.statusInactivo {
    background-color: #ff2222;
}

/* modales */
.modal-referencias{
    background: linear-gradient(94.6deg, #32383E -60.81%, #17191C 185.78%);
    padding-bottom: 0px;
}
.modal-referencias .ant-modal-header {
    background-color: #171819;
    border-bottom: none!important;
}

.modal-referencias .ant-modal-title,
.modal-referencias .ant-modal-close-icon {
    color: #CCC;
    font-size: 14pt;
}

.modal-referencias .ant-modal-body {
    overflow: auto;
}

.modal-referencias .ant-modal-content {
    height: 100%;
    background-color: transparent!important;
    overflow: auto;
}

.modal-referencias .ant-modal-body div {
    overflow: auto;
}

.modal-referencias .ant-modal-footer {
    display: none;
}

.modal-referencias {
    width: 80% !important;
    max-width: 850px;
    height: 65% !important;
    max-height: 800px;

}

.modal-referencias .ant-modal-title,
.modal-referencias .ant-modal-close-icon {
    color: #CCC;
    font-size: 14pt;
}

.ant-modal-confirm-confirm div{
    margin-left: 0px !important;
}

.ant-modal-confirm-confirm .ant-modal-content{
    background-color: transparent !important;
    background: linear-gradient(94.6deg, #32383E -60.81%, #17191C 185.78%) !important;
}

.ant-modal-confirm-confirm .ant-modal-content .ant-modal-body{
    padding:22px 22px 14px;
}

.ant-modal-confirm-confirm .ant-modal-content .ant-modal-confirm-body svg {display: none;}

.ant-modal-content  .IconoModal svg {display: block !important;}

.ant-modal-confirm-confirm .ant-modal-content .ant-modal-confirm-title{
    color: #FFF;
}

.ant-modal-confirm-confirm .ant-modal-content  .ant-modal-confirm-content{
    color: #899aa4;
}

.ant-modal-confirm-confirm .ant-modal-content  .ant-modal-confirm-btns .ant-btn{
    background: #ff4d4f !important;
    color: #fff !important;
    border-color: #ff4d4f !important;
}

.ant-modal-confirm-confirm .ant-modal-content  .ant-modal-confirm-btns .ant-btn-dangerous{
    background: #2a9f9e !important;
    color: #fff !important;
    border-color: #2a9f9e !important;

}

.overflow-x-null{
    overflow-x: hidden;
}


/* estilos genericos */
.border {
	border: solid 1px red;
}
.center{
	display: flex !important;
	justify-content: center;
	align-items: center;
}

.flex-start{
    display: flex !important;
    justify-content: flex-start;
    align-items: center;
}

.pd-1{
    padding: 1rem !important;
}
.pd-2{
    padding:2rem !important;
}
.pl-1{
    padding-left: 1rem !important;
}

.pr-1{
    padding-right: 1rem !important;
}
.pb-3{
    padding-bottom: 3rem !important;
}
.m-0{
    margin: 0 !important;
}
.pt-1{
    padding-top: 1rem;
}

.pb-1{
    padding-bottom: 1rem;
}
.ml-1{
    margin-left: 1rem !important;
}
.ml-2{
    margin-left: 2rem !important;
}
.ml-3{
    margin-left: 3rem !important;
}
.mt-1{
    margin-top: 1rem !important;
}
.mt-2{
    margin-top: 2rem !important;
}
.mt-3{
    margin-top: 3rem !important;
}
.mb-1{
    margin-bottom: 1rem !important;
}
.mb-2{
    margin-bottom: 2rem !important;
}
.mb-3{
    margin-bottom: 3rem !important;
}
.mr-1{
    margin-right: 1rem !important;
}
.mr-2{
    margin-right: 2rem !important;
}
.text-center{
    text-align: center;
}
.text-left{
    text-align: left;
}
.text-right{
    text-align: right;
}

.flex-left{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

/* Texts */
.accion-text, .accion-text span{
    color:#899AA4
}


/*Modal detalle*/
 .modalDetalle{
    background: linear-gradient(94.6deg, #32383E -60.81%, #17191C 185.78%);
}

.modalDetalle .ant-modal-header {
    background-color: #171819;
}

.mod.modalDetalle .ant-modal-title,
.mod.modalDetalle .ant-modal-close-icon {
    color: #CCC;
    font-size: 14pt;
}

.modalDetalle .ant-modal-body {
    overflow: auto;
    padding: 15px 25px;
    padding-bottom: 30px;
}

.modalDetalle .ant-modal-content {
    height: 100%;
    overflow: auto;
}


.modalDetalle .ant-modal-footer {
    display: none;
}

.modalDetalle {
    width: 80% !important;
    max-width: 500px;
    height: auto !important;
    max-height: 800px;
}

 .modalDetalle .ant-modal-body p strong{
     display: inline-block;
     margin-bottom: 5px !important;
 }


/*Button Add*/
.btnAddPlus{
    border: none !important;
    display:block !important;
    position:fixed !important;
    bottom:10px !important;
    right: 15px !important;
    z-index: 10 !important;
    width:  60px !important;
    height: 60px !important;
    border-radius: 100px !important;
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.35), 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    background: linear-gradient(126.63deg, #09eb60 11.98%, #09eb60 83.35%) !important; 
    padding: 0px !important;
    padding-top: 3px !important;
    padding-left: 2px !important;
}

.btnAddPlus:active,
.btnAddPlus:focus,
.btnAddPlus:hover{
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.45), 0px 4px 4px rgba(0, 0, 0, 0.35) !important;
    background: #09eb60;  
}

.addVisitante{
    width: 100%;
    border-radius: 5px !important; 
    border: none !important;
    margin-bottom: 5px;
    color: #fff!important;
    background: linear-gradient(126.63deg, #2A9F9E 11.98%, #2A9F9E 83.35%) !important; 

}
.addVisitante span{
  overflow-wrap: break-word;
}

/*Global nuevos estilos*/
 .input-search 
{
   width: 90% !important;
}

 
.input-search .ant-input-group-addon
{
   display: none !important;
}

.row-enc-vista .ant-col {
    margin-bottom: 10px !important;
}

.row-enc-vista .title{
    font-size: 22px !important ;
    margin: 0 !important;
    text-align: left !important;
}

.row-enc-vista  .input-search 
{
    background: #494A4D !important;
    border-radius: 8px !important;
    min-height: 10px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 40px;
}

.row-enc-vista  .input-search  .ant-input-prefix
{
    padding: 0px !important;
    margin-left: 0px;
    margin-right: 10px;
}

.row-enc-vista  .input-search  .ant-input-prefix svg
{
    padding: 0px !important;
    margin: 0px !important;
}

.row-enc-vista  .input-search  .ant-input
{
    background: #494A4D !important;
    color: #9EA7AC !important;
    background: transparent !important;
    border-color:transparent ;
    box-shadow: none !important;
}

.row-enc-vista  .input-search  .ant-input::placeholder
{
    color: #9EA7AC !important;
}


.row-enc-vista  .input-search  input.ant-input
{
    padding: 0px !important;
    margin: 0px !important;
    max-height: none !important;
    min-height: 40px !important;
    height: 40px !important;
}

.row-enc-vista .filters-drop  .ant-select-arrow ,
.row-enc-vista  .ant-input-suffix,
.modal-aide  .ant-form-item-label > label.ant-form-item-required::before
{
    display: none !important;
    visibility: hidden !important;
}

.row-enc-vista .ant-picker-range{
    border-radius: 0px !important;
    height: 15px !important;
    min-height: 35px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;  
    box-shadow: none !important;
    background: transparent;
    border: solid 1px #33F898 !important;
}

.row-enc-vista  .ant-picker input,
.row-enc-vista  .ant-picker input::placeholder,
.row-enc-vista .ant-picker .anticon-swap-right,
.row-enc-vista .ant-picker .anticon-calendar{
    color: #33F898 !important;
}

.row-enc-vista .ant-picker-range .ant-picker-clear{
    background: #24282c !important;
    right: 10px !important;
}

.row-enc-vista .ant-picker-range .ant-picker-clear .anticon{
   color: #33F898;
}
 
.row-enc-vista .filters-drop .ant-select-selector{
   background: transparent !important;
}

.row-enc-vista .filters-drop .ant-select-selection-search{
    border-right: 0px;
}

.row-enc-vista .filters-drop .ant-select:not(.ant-select-disabled):hover .ant-select-selector{
    border-right-width: 0px !important;
    background: transparent !important;
}

.row-enc-vista .ant-select-selection-placeholder {
    color: #33F898 !important;
    opacity: 1 !important;
}

.row-enc-vista .ant-select-selector{
    border: none !important;
}

.row-enc-vista .ant-select-selection-item{
    color: #32f797;
}

.ant-form-item .ant-select{
    border-radius: 50px !important;
}

.input-form {
    min-height: 45px !important;
}

.ant-modal  {
    padding-bottom: 0px !important;
}


.ant-select .ant-select-clear{
    right: 10px !important;
    margin-top: -4px !important;
}

.ant-select .anticon-close-circle svg {
    color: rgba(255, 0, 0, 0.85) !important;
}


.modal-aide .ant-modal-close-x{
    display: none !important;
    
}

.areaCamara{
    position: absolute;
    float: left;
}
 
.iconoCamaraFija{
    color:#F08532 ;
}


.iconoCamaraLPR{
    color:#75FB4C !important;
}


.card-descriptionMapa{
    background: #32383E !important;
    width: 250px;
    height: auto ;
    border-radius: 10px !important;
    border: 1px solid  #32383E !important;
}

.card-descriptionMapa .ant-card-bordered {
    border-radius: 10px !important;
    border: 1px solid  #32383E !important;
}

.card-descriptionMapa .ant-card-body { 
    background: transparent !important;
    border: none !important;
}


.row-descMapa{
    width: 100%;
    float: left;
}

.row-descMapa .ant-col{
    float: left;
    display: block;
    padding: 0px;
    margin-bottom: 5px;
}

.row-descMapa .ant-col  .ant-typography{
    color:#DDD !important;
    top: 12px;
    position: relative;
    float: right;
    text-align: left;
    width: 120px;
    font-weight: 600;
    font-size: 11pt;
    margin-left: 10px;
}


.ant-upload.ant-upload-select.ant-upload-select-text{
    width: 100%;
}

.avatar-uploader .ant-btn{
    background: #222325 !important;
    color: #FFF;
    border-radius: 10px;
    border:none !important;
    box-shadow: inset -4px -2px 8px #575757, inset 4px 2px 8px rgb(13 13 13 / 48%);
    width: 100% !important;
    height: 40px !important;
}
 

.avatar-uploader .ant-btn{
    background: #222325 !important;
    color: #FFF;
    border-radius: 20px;
    border:none !important;
    box-shadow: inset -4px -2px 8px #575757, inset 4px 2px 8px rgb(13 13 13 / 48%);
    width: 100% !important;
    height: 42px !important;
}

.ant-upload-list-item-name,
span.anticon-paper-clip
{
 color: #899AA4 !important;   
}

.anticon-delete{
    color:red !important
}

.ant-upload-list-item-info,
.ant-btn.ant-btn-text.ant-btn-sm.ant-btn-icon-only.ant-upload-list-item-card-actions-btn{
    background: transparent !important;
    background: transparent !important;
}


.ant-list ul.ant-list-items,
.ant-table
 {
    min-height: 300px !important;
    overflow-y: auto !important;
}

.pagination-table{
    position: relative;
    display: block;
}


.ant-list .ant-row:hover .ant-col .ant-btn-link span,
.ant-list  .ant-row:hover .ant-col a,
.ant-table  .ant-table-row:hover td a,
.blackTheme  .ant-row:hover .ant-col .ant-btn-link span,
.blackTheme  .ant-row:hover .ant-col .accion-text,
.blackTheme .ant-table tbody > tr:hover > td  .ant-btn-link span,
.list-item-access.blackTheme  li:hover  .item-access-row-column,
.list-item-access.blackTheme  li:hover  .item-access-row-column span.ant-typography,
.div-ultimosAccesos .div-notificacion:hover ,
.div-ultimosAccesos .div-notificacion:hover  span.ant-typography,
.div-ultimosAccesos .div-notificacion:hover  a
{
    color: #FFF !important;
    background:  #04FAA1 !important;
    color: #fff !important;
}

